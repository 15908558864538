
export const environment = {
  production: false,
  pr1ApiUrl: 'https://pr1riteaid-staging.przone.net/pr1core/Private',
  appKey: 'promotion engine',
  environment: 'staging',
  appMode: 'standalone',
  shopifyAuth: {
    apiKey: '',
    host: '',
  },
  applicationInsights: {
    instrumentationKey: '20dde356-93ef-496d-9edd-f449702be4a8', // qa environment
    enableCorsCorrelation: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
  },
  knockApp: {
    FEED_KEY: 'b8a11870-97b0-47a5-a95c-9dee1131200e',
    CLIENT_KEY: 'pk_ZJ1Sf65ZCFJbjDuh_LQaG4kT8jz0kABr6jpqo8l1C_Y',
  },
  auth: {
    domain: 'pr1-production.us.auth0.com',
    clientId: 'zALx0U0jPBNeYcOxNjJgFnT9y30D8YlF',
    audience: 'https://pr1-std-riteaid-staging-api.przone.net',
    organization: 'org_1yYgpL14H1i79PyQ',
    redirectUri: window.location.origin + "/pr1core/",
  },
  httpInterceptor: {
    allowedList: [`https://pr1riteaid-staging.przone.net/pr1core/Private/*`],
  },
  blobStorage: {
    kroger: {
      assetsUrl: "https://pr1riteaidblob389.blob.core.windows.net/public/assets"
    },
    riteaid: {
      assetsUrl: "https://pr1riteaidblob389.blob.core.windows.net/public/assets"
    },
    standard: {
      assetsUrl: "https://pr1riteaidblob389.blob.core.windows.net/public/assets"
    },
    walgreens: {
      assetsUrl: "https://pr1riteaidblob389.blob.core.windows.net/public/assets"
    },
  }
};
