import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  map,
  of,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs';
import { DEFAULT_PAGING, Filter, OrganizationService } from 'src/app/@shared';
import { KnockNotificationService } from 'src/app/@shared/services/knock-notification.service';
import { environment as env } from 'src/environments/environment';
import { v4 as uuidv4 } from 'uuid';
import { OfferPromoService } from './offerpromo.service';

@Injectable({
  providedIn: 'root',
})
export class EventWorkflowService implements OnDestroy {
  baseUrl = env.pr1ApiUrl;
  public eventName = 'Event Name';
  public selectedEventId = '';
  public authToken = '';
  public headersWithBearerToken: any;
  public usersTableRefData: any;
  private usersTask$: any;
  private emailAlert$: any;
  private selectedWorkflowId: any;
  private usersOfActiveStage = [];

  public templatesSelected = new Subject();
  public selectedUsersToAddToWorkflow = new Subject();
  public usersTableRefDataSubject = new Subject();
  public subscription$ = new Subscription();

  public loadingBehaviorSubject = new BehaviorSubject<boolean>(false);

  private workflowGroupBehaviorSubject = new BehaviorSubject<
    string | undefined
  >(undefined);
  private pageBehaviorSubject = new BehaviorSubject(DEFAULT_PAGING);
  private sortBehaviorSubject = new BehaviorSubject({
    active: 'Name',
    direction: 'desc',
  });
  private searchBehaviorSubject = new BehaviorSubject<string>('');
  private reloadBehaviorSubject = new BehaviorSubject<string>('');
  private filterBehaviorSubject = new BehaviorSubject<Filter[]>([]);

  public isLoading$ = this.loadingBehaviorSubject.asObservable();
  public page$ = this.pageBehaviorSubject.asObservable();
  public reload$ = this.reloadBehaviorSubject.asObservable();
  public workflowGroup$ = this.workflowGroupBehaviorSubject.asObservable();

  public params$ = combineLatest([
    this.pageBehaviorSubject, // add debounce if we need to wait for user input ex: .pipe(debounceTime(300)),
    this.sortBehaviorSubject,
    this.searchBehaviorSubject.pipe(debounceTime(300)),
    this.filterBehaviorSubject.pipe(debounceTime(50)),
    this.reloadBehaviorSubject,
  ]).pipe(
    distinctUntilChanged((previous, current) => {
      // if the values coming down this pipe are the same, don't continue the pipe
      return JSON.stringify(previous) === JSON.stringify(current);
    }),
    map(([page, sort, search, filters, reload]) => {
      let _orderby = `Detail/${sort.active} ${sort.direction}`;
      if (sort.active == 'Name') {
        _orderby = `${sort.active} ${sort.direction}`;
      }

      // set the query string parameters
      let params: HttpParams = new HttpParams({
        fromObject: {
          Skip: page.pageIndex * page.pageSize,
          Limit: page.pageSize,
          Orderby: _orderby,
        },
      });

      // if there is a search, add the search to the parameters
      if (search.length) {
        params = params.append('$search', `"${search}"`);
      }

      return params;
    })
  );

  public eventWorkflowParams$ = combineLatest([
    this.pageBehaviorSubject, // add debounce if we need to wait for user input ex: .pipe(debounceTime(300)),
    this.sortBehaviorSubject,
    this.searchBehaviorSubject.pipe(debounceTime(300)),
    this.filterBehaviorSubject.pipe(debounceTime(50)),
    this.reloadBehaviorSubject,
    this.workflowGroupBehaviorSubject,
  ]).pipe(
    distinctUntilChanged((previous, current) => {
      // if the values coming down this pipe are the same, don't continue the pipe
      return JSON.stringify(previous) === JSON.stringify(current);
    }),
    map(([page, sort, search, filters, reload, workflowGroup]) => {
      let _orderby = `Detail/${sort.active} ${sort.direction}`;
      if (sort.active == 'Name') {
        _orderby = `${sort.active} ${sort.direction}`;
      }

      // set the query string parameters
      let params: HttpParams = new HttpParams({
        fromObject: {
          Skip: page.pageIndex * page.pageSize,
          Limit: page.pageSize,
          Orderby: _orderby,
          group: workflowGroup!,
        },
      });

      if (!workflowGroup) {
        params = new HttpParams({
          fromObject: {
            Skip: page.pageIndex * page.pageSize,
            Limit: page.pageSize,
            Orderby: _orderby,
          },
        });
      }

      // if there is a search, add the search to the parameters
      if (search.length) {
        params = params.append('$search', `"${search}"`);
      }

      return params;
    })
  );

  private masterTemplateAPIEndpoint = `${env.pr1ApiUrl}/${this.orgService.organization?.apiPath}/${this.orgService.organization?.version}/WorkflowTemplate/GetWorkflowTemplate`;

  private getWorkflowAPIEndpoint = `${env.pr1ApiUrl}/${this.orgService.organization?.apiPath}/${this.orgService.organization?.version}/WorkflowTemplate/GetWorkflowTemplateDetails`;

  // private getWorkflowTypeAPIEndpoint = `${env.pr1ApiUrl}/${this.orgService.organization?.apiPath}/${this.orgService.organization?.version}/WorkflowType/GetWorkflowType`;

  private getUsersAPIEndpoint = `https://pr1-std-sysadmin-${env.environment
    }-portal-api-app.azurewebsites.net/standard/V1/AuthUsers/GetOrganizationUsers?orgId=${localStorage.getItem(
      'orgId'
    )}`;

  private createNewWorkflowAPIEndpoint = `${env.pr1ApiUrl}/${this.orgService.organization?.apiPath}/${this.orgService.organization?.version}/Workflow/Update`;

  private getMasterRefData = `${env.pr1ApiUrl}/${this.orgService.organization?.apiPath}/${this.orgService.organization?.version}/WorkflowTemplate/GetMasters`;

  private newWorkflowGroupAPIEndpoint = `${env.pr1ApiUrl}/${this.orgService.organization?.apiPath}/${this.orgService.organization?.version}/WorkflowGroup/CreateWorkflowGroup`;

  private addOrCreateWorkflowAPIEndpoint = `${env.pr1ApiUrl}/${this.orgService.organization?.apiPath}/${this.orgService.organization?.version}/Workflow/Create`;

  private getWorkflowsForFeatureAPIEndpoint = `${env.pr1ApiUrl}/${this.orgService.organization?.apiPath}/${this.orgService.organization?.version}/Workflow/GetWorkflows`;

  private getWorkflowDetailsForFeatureAPIEndpoint = `${env.pr1ApiUrl}/${this.orgService.organization?.apiPath}/${this.orgService.organization?.version}/Workflow/GetWorkflowDetails`;

  private updateStageAPIEndpoint = `${env.pr1ApiUrl}/${this.orgService.organization?.apiPath}/${this.orgService.organization?.version}/Workflow/UpdateStageStatus`;

  private deleteWorkflowAPIEndpoint = `${env.pr1ApiUrl}/${this.orgService.organization?.apiPath}/${this.orgService.organization?.version}/Workflow/Delete`;

  private getWorkflowPermissionsApiEndpoint = `${env.pr1ApiUrl}/${this.orgService.organization?.apiPath}/${this.orgService.organization?.version}/Workflow/GetPermissions`;

  workflowGroupsRefData: any = undefined;

  roles = {
    reviewer: 0,
    approver: 1,
    editor: 2,
  };
  readOnlyMode: any = null;
  private selectedEventIdFromUrl: string = '';
  public applicableWorkflowPermissions: any;
  public activeStageOfSelectedEvent: any;
  public userHasAccessInCurrentWorkflow: any = false;
  public userRoleInCurrentWorkflow = new BehaviorSubject(0);
  public permissionForCurrentWorkflow$ = new Subject();
  public currentWorkflowPermissions: any;
  selectedWorkflowGroup = '';
  public activeWorkflowId: string | null = null;

  constructor(
    private httpClient: HttpClient,
    public orgService: OrganizationService,
    public router: Router,
    public knockNotificationService: KnockNotificationService,
    private route: ActivatedRoute,
    private offerpromoService: OfferPromoService<any, any>
  ) {
    // if (this.selectedEventId.trim().length == 0) {
    //   this.tryGettingEventIdFromUrl();
    // }

    this.processWorkflowPermissionWithEventIdFromURL();
  }

  tryGettingEventIdFromUrl() {
    const currentUrl = this.router.routerState.snapshot.url;
    const cua = currentUrl.split('/');
    if (cua.includes('events')) {
      this.setSelectedEventId(cua[3]);
    }
  }

  private getRefData$ = (token: string) =>
    this.httpClient.get(this.getMasterRefData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

  private getListWorkflowTemplates$ = this.eventWorkflowParams$.pipe(
    tap(() => this.offerpromoService.loadingBehaviorSubject.next(true)), // set isLoading to true
    switchMap((_params) => {
      // if (this.selectedWorkflowGroup?.trim()?.length > 0) {
      //   _params.append('group', this.selectedWorkflowGroup);
      // }
      return this.httpClient.get(this.masterTemplateAPIEndpoint, {
        params: _params,
      });
    }),
    tap(() => this.offerpromoService.loadingBehaviorSubject.next(false)), // set isLoading to false
    shareReplay(1), // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
    map((data) => {
      return data;
    })
  );

  public Templates$ = this.getListWorkflowTemplates$!.pipe(
    map((res: any) => res['value'])
  );

  public totalRecords$ = this.getListWorkflowTemplates$!.pipe(
    map((res: any) => res['@odata.count'])
  );

  public createUpdateWorkflow(
    payload: any,
    workflowId: any,
    Id: any,
    Name: any,
    stageCompletedNotificationMsg: any = 'Created / Updated Workflow.'
  ): Observable<Object> {
    return this.httpClient.post(this.createNewWorkflowAPIEndpoint, payload, {
      responseType: 'text',
      // headers: {
      //   Authorization: `Bearer ${this.orgService.silentAuthToken}`,
      // },

      params: {
        workflowId,
        Id,
        Name,
      },
    });
    // .pipe(
    //   tap(
    //     this.triggerKnockNotificationSideEffect(stageCompletedNotificationMsg)
    //   )
    // );
  }

  public getMasterTemplate(): Observable<Object> {
    return this.getListWorkflowTemplates$!;
  }

  public getWorkflow(token: string, templateId: string) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return this.httpClient
      .get(this.getWorkflowAPIEndpoint, {
        params: {
          templateId,
        },
        headers,
      })
      .pipe(
        map((data: any) => {
          const wd = data;
          const mappedData = {
            ...data,
            id: wd.Id,
            template_info: {
              template_name: wd.TemplateName,
              template_owner: wd.UserId,
              template_group: wd.Channel,
              template_category: wd.WorkflowType,
            },
            workflow_config: wd.Tasks?.map((s: any) => s.Name),
            workflow_stages: wd.Tasks?.map((s: any) => {
              return {
                stage_name: s.Name,
                stage_description: s.Description,
                starting_trigger: s.StartTrigger,
                ending_trigger: s.EndTrigger,
                deadline: s.Deadline,
                users: s.Users?.map((u: any) => ({
                  first_name: u.FirstName,
                  last_name: u.LastName,
                  task: u.UserTask,
                  email_alerts: u.EmailAlert,
                  id: u.Id,
                  role: u.Role,
                })),
              };
            }),
          };

          return mappedData;
        })
      );
  }

  public getRefDataForEditWorkflowControls() {
    return this.getRefData$(this.orgService.silentAuthToken).pipe(
      map((data: any) => {
        let startTriggers = data.StartTriggers?.map((s: any) => ({
          ...s,
          label: s.Name,
          value: s.Id,
        }));

        let endTriggers = data.EndTriggers?.map((e: any) => ({
          ...e,
          label: e.Name,
          value: e.Id,
        }));

        let tasks = data.UserTasks?.map((t: any) => ({
          ...t,
          label: t.Name,
          value: t.Id,
        }));

        let emailAlerts = data.EmailAlerts?.map((e: any) => ({
          ...e,
          label: e.Name,
          value: e.Id,
        }));

        let workflowGroups = data.WorkflowGroups?.map((w: any) => ({
          ...w,
          label: w.Name,
          value: w.Id,
        }));

        this.usersTableRefData = {
          tasks,
          emailAlerts,
        };

        this.usersTableRefDataSubject.next(this.usersTableRefData);

        return {
          startTriggers,
          endTriggers,
          tasks,
          emailAlerts,
          workflowGroups,
        };
      })
    );
  }

  public getRefDataForWorkflowUsersTable() {
    return combineLatest([this.usersTask$, this.emailAlert$]).pipe(
      map((data: any) => {
        let tasks = data[0].value;
        let emailAlerts = data[1].value;

        tasks = tasks?.map((t: any) => ({
          ...t,
          label: t.UserTaskName,
          value: t.UserTaskName,
        }));

        emailAlerts = emailAlerts?.map((e: any) => ({
          ...e,
          label: e.EmailAlertName,
          value: e.EmailAlertName,
        }));

        return {
          tasks,
          emailAlerts,
        };
      })
    );
  }

  public createWorkflowGroup(payload: any) {
    return this.httpClient.post(this.newWorkflowGroupAPIEndpoint, payload, {
      responseType: 'text',
      // headers: {
      //   Authorization: `Bearer ${this.orgService.silentAuthToken}`,
      // },
    });
  }

  public addWorkflow(
    templateId: string,
    featureName: string,
    featureId: string
  ) {
    return this.httpClient.get(
      `${this.addOrCreateWorkflowAPIEndpoint}?templateId=${templateId}&featureName=${featureName}&featureId=${featureId}`,
      {
        responseType: 'text',
        // headers: {
        //   Authorization: `Bearer ${this.orgService.silentAuthToken}`,
        // },
      }
    );
  }

  public getWorkflowsForFeature(featureId: string, token: string) {
    // return this.httpClient
    //   .get(`${this.getWorkflowsForFeatureAPIEndpoint}?featureId=${featureId}`)
    //   .pipe(
    //     map((resp: any) => {
    //       return resp.value;
    //     })
    //   );

    return this.params$.pipe(
      tap(() => this.offerpromoService.loadingBehaviorSubject.next(true)), // set isLoading to true
      switchMap((_params) => {
        return this.httpClient.get(
          `${this.getWorkflowsForFeatureAPIEndpoint}?featureId=${featureId}`
        );
      }),
      tap(() => this.offerpromoService.loadingBehaviorSubject.next(false)), // set isLoading to false
      shareReplay(1), // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
      map((resp: any) => {
        return resp.value;
      })
    );
  }

  rolesRefData = [
    { label: 'Reviewer', value: 0 },
    { label: 'Approver', value: 1 },
  ];

  getRoleLabelFromValue(label: string) {
    const i = this.rolesRefData.findIndex((r) => r.label == label);
    if (i > -1) {
      return this.rolesRefData[i].value;
    }
    return 0;
  }

  public getWorkflowDetailsForFeature(workflowId: any, token: string) {
    this.selectedWorkflowId = workflowId;
    return this.params$.pipe(
      tap(() => this.offerpromoService.loadingBehaviorSubject.next(true)), // set isLoading to true
      switchMap((_params) => {
        workflowId = workflowId ? workflowId : this.selectedWorkflowId;
        return this.httpClient
          .get(
            `${this.getWorkflowDetailsForFeatureAPIEndpoint}?workflowId=${workflowId}`,
            {
              // headers: {
              //   Authorization: `Bearer ${
              //     token ? token : this.orgService.silentAuthToken
              //   }`,
              // },
            }
          )
          .pipe(
            map((data: any) => {
              const wd = data;
              const activeStages = wd.Tasks?.filter(
                (s: any) => s?.StageStatus?.trim()?.toLowerCase() == 'active'
              );
              const mappedData = {
                ...data,
                id: wd.Id,
                template_info: {
                  template_name: wd.TemplateName,
                  template_owner: wd.UserId,
                  template_group: wd.Channel,
                  template_category: wd.WorkflowType,
                },
                workflow_status: wd.WorkflowStatus,
                workflow_config: wd.Tasks?.map((s: any) => s.Name),
                workflow_stages: wd.Tasks?.map((s: any) => {
                  return {
                    stage_name: s.Name,
                    stage_description: s.Description,
                    stage_status: s.StageStatus,
                    stage_id: s.Id,
                    stage_task: s.StageTask,
                    sort_order: s.SortOrder,
                    users: s.Users?.map((u: any) => ({
                      first_name: u.FirstName,
                      last_name: u.LastName,
                      task: u.UserTask,
                      email_alerts: u.EmailAlert,
                      email: u.Email,
                      id: u.Id,
                      role: this.getRoleFromValue(u.Role),
                    })),
                  };
                }),
                ActiveStageName:
                  activeStages.length > 0 ? activeStages[0]?.Name : null,
              };

              return mappedData;
            })
          );
      }),
      tap(() => this.offerpromoService.loadingBehaviorSubject.next(false)), // set isLoading to false
      shareReplay(1), // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
      map((data) => {
        return data;
      })
    );
  }

  public getRoleFromValue(val: number) {
    if (val == 0) {
      return 'Reviewer';
    } else if (val == 2) {
      return 'Approver';
    } else if (val == 1) {
      return 'Editor';
    } else {
      return 'Reviewer';
    }
  }

  public updateStageStatus(
    workflowId: any,
    taskId: string,
    token: string,
    status = 'skipped',
    notificationMessage = 'Updated Stage Status.'
  ) {
    return this.httpClient
      .get(
        `${this.updateStageAPIEndpoint}?workflowId=${workflowId}&taskId=${taskId}&status=${status}`,
        {
          responseType: 'text',
          // headers: {
          //   Authorization: `Bearer ${
          //     token ? token : this.orgService.silentAuthToken
          //   }`,
          // },
        }
      )
      .pipe(tap(this.triggerKnockNotificationSideEffect(notificationMessage)));
  }

  public deleteWorkflow(workflowId: string, token: string) {
    return this.httpClient.delete(
      `${this.deleteWorkflowAPIEndpoint}?id=${workflowId}`,
      {
        // headers: {
        //   Authorization: `Bearer ${
        //     token ? token : this.orgService.silentAuthToken
        //   }`,
        // },
      }
    );
  }

  public getWorkflowPermissions(featureId: string, token?: string) {
    return this.httpClient
      .get(`${this.getWorkflowPermissionsApiEndpoint}?featureId=${featureId}`)
      .pipe(
        tap((data: any) => {
          // If any active workflow present, save it's workflow id
          const i = (data as any[]).findIndex(
            (d: any) => d.Status == 'in_progress'
          );
          if (i > -1) {
            this.activeWorkflowId = data[i].WorkflowId;
          } else {
            this.activeWorkflowId = null;
          }
        })
      );
  }

  triggerKnockNotificationSideEffect(msg: string) {
    return () => {
      const collectionIds = this.usersOfActiveStage?.map((u: any) =>
        (u.email as string)?.toLowerCase()?.trim()
      );
      console.log('Knock notification triggered with the following data: ', {
        collectionIds,
        customMessage: msg,
        workflowName: 'pr1-notification',
        collectionName: '$users',
      });
      this.knockNotificationService.triggerCustomKnockNotification(
        collectionIds,
        msg,
        'pr1-notification',
        '$users',
        msg
      ).subscribe(res => { });
    };
  }

  triggerKnockEmail(
    msg: string,
    workflowName: string,
    collectionName = '$users',
    usersToNotify: any
  ) {
    console.log('Knock EMAIL triggered with the following data: ', {
      collectionIds: usersToNotify,
      customMessage: msg,
      workflowName,
      collectionName,
    });
    this.knockNotificationService.triggerKnockNotification(
      usersToNotify,
      msg,
      workflowName,
      collectionName
    );
  }

  triggerKnockNotification(
    collectionIds: any[],
    msg: string | undefined,
    listOfCustomMessages?: string[] | undefined
  ) {
    if (!listOfCustomMessages) {
      this.knockNotificationService.triggerKnockNotification(
        collectionIds,
        msg
      );
    }
    if (listOfCustomMessages) {
      this.knockNotificationService.triggerKnockNotification(
        collectionIds,
        undefined,
        'pr1-notification',
        '$users',
        listOfCustomMessages
      );
    }
  }

  triggerCustomKnockNotification(
    collectionIds: any[],
    msg: string | undefined,
    colelctionName: string,
    workflowName: string,
    inAppMsg: string
  ) {
    this.knockNotificationService.triggerCustomKnockNotification(
      collectionIds,
      msg,
      workflowName,
      colelctionName,
      inAppMsg
    ).subscribe(res => { });
  }

  page(page: any) {
    this.pageBehaviorSubject.next(page);
  }

  reload() {
    this.reloadBehaviorSubject.next(uuidv4());
  }

  setUsersOfActiveStage(u: any) {
    this.usersOfActiveStage = u;
  }

  workflowGroup(g: string) {
    this.workflowGroupBehaviorSubject.next(g);
    this.selectedWorkflowGroup = g;
  }

  processWorkflowPermissionWithEventIdFromURL() {
    const intervalForWorkflowPermissions = setInterval(() => {
      if (
        this.orgService &&
        this.orgService.organization &&
        this.orgService.organization?.apiPath != undefined &&
        this.orgService.organization?.version != undefined
      ) {
        this.recordPermissionsForSelectedEvent();
        clearInterval(intervalForWorkflowPermissions);
      }
    }, 1000);
  }

  getPathSegmentsAndSetNewEventIdIfNeeded(pathSegments: any[]) {
    const ei = pathSegments.indexOf('events');
    if (pathSegments[ei + 1]) {
      this.setSelectedEventId(pathSegments[ei + 1]);
    }
  }

  recordPermissionsForSelectedEvent() {
    // Initial call when page is loaded
    if (this.router.url.includes('/events')) {
      const pathSegments = this.router.url.split('/');
      this.getPathSegmentsAndSetNewEventIdIfNeeded(pathSegments);
    }

    // Subscribing to any new changes in the url
    this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        if (e.urlAfterRedirects.includes('/events')) {
          const pathSegments = e.urlAfterRedirects.split('/');
          this.getPathSegmentsAndSetNewEventIdIfNeeded(pathSegments);
        }
      }
    });
  }

  getWorkflowPermissionsForSelectedEvent(eventId?: string) {
    const r_subscription$ = new Subscription();
    r_subscription$.add(
      this.getWorkflowPermissions(
        eventId ? eventId : this.offerpromoService.eventId,
        this.orgService.silentAuthToken
      ).subscribe((permissions: any) => {
        this.permissionForCurrentWorkflow$.next(permissions);
        this.currentWorkflowPermissions = permissions;

        // If no permissions found, restrict access
        if (!permissions || permissions.length == 0) {
          this.activeStageOfSelectedEvent = undefined;
          this.applicableWorkflowPermissions = undefined;
          this.userHasAccessInCurrentWorkflow = undefined;
          return;
        }

        const inProgressWorkflowIndex = permissions.findIndex(
          (p: any) => p.Status == 'in_progress'
        );

        // If an active workflow exists
        if (inProgressWorkflowIndex > -1) {
          this.applicableWorkflowPermissions =
            permissions[inProgressWorkflowIndex];

          const activeStageIndex =
            this.applicableWorkflowPermissions?.Stages.findIndex(
              (s: any) => s.Status == 'active'
            );

          // Check if active stage index
          if (activeStageIndex > -1) {
            this.activeStageOfSelectedEvent =
              this.applicableWorkflowPermissions?.Stages[activeStageIndex];

            // Check if logged-in user has permission to access this event
            const userFoundIndex =
              this.activeStageOfSelectedEvent?.Users?.findIndex(
                (u: any) =>
                  u.Email?.toLowerCase()?.trim() ==
                  this.orgService?.loggedInUser?.email?.toLowerCase().trim()
              );

            let user: any = null;
            let userCanOnlyView = false;

            if (userFoundIndex > -1) {
              // User has access to event in current workflow
              this.userHasAccessInCurrentWorkflow = true;

              user = this.activeStageOfSelectedEvent?.Users[userFoundIndex];
              this.userRoleInCurrentWorkflow.next(user.Role);

              if (user.Role == this.roles.reviewer) {
                // User is reviewer & can only view the event
                userCanOnlyView = true;
              } else {
                // User is approver & can edit the event
                userCanOnlyView = false;
              }
            } else {
              // User DOES NOT have access to event in current workflow

              this.userHasAccessInCurrentWorkflow = false;
              if (
                this.router.url.includes('/events') &&
                !this.router.url.includes('/details') &&
                !this.router.url.includes('/workflow')
              ) {
                // this.orgService.showShellBanner(true);
                // this.router.navigate([
                //   `/${this.orgService.organization?.apiPath.toLowerCase()}/events`,
                // ]);

                userCanOnlyView = true;
              }
            }

            // User is reviewer & hence has only read only access
            if (userCanOnlyView) {
              this.readOnlyMode = true;
              this.offerpromoService.setOfferDetailsReadOnly(this.readOnlyMode);
            }
          }
        } else {
          // If there is no active workflow
          this.applicableWorkflowPermissions = undefined;
        }

        setTimeout(() => r_subscription$.unsubscribe(), 0);
      })
    );
  }

  public emitCurrentWorkflowPermissions() {
    this.permissionForCurrentWorkflow$.next(this.currentWorkflowPermissions);
  }

  public setSelectedEventId(val: string) {
    this.selectedEventIdFromUrl = val;
    if (val && val.trim().length > 0) {
      this.getWorkflowPermissionsForSelectedEvent(val);
    }
  }

  public getSelectedEventId() {
    return this.selectedEventIdFromUrl;
  }

  public setLoadingSubjectInOfferPromoService(val: boolean) {
    this.offerpromoService.loadingBehaviorSubject.next(val);
  }

  getInappBody(eventName: string,
    workflowTemplateName: string,
    stageName: string,
    url: string) {
    return `Event <b>${eventName}</b> is assigned for your review. <b>${stageName}</b> of ${workflowTemplateName} workflow is In Progress. <br/><a href="${url}">View in PR1</a>`;
  }

  getEmailBody(
    eventName: string,
    workflowTemplateName: string,
    stageName: string,
    url: string
  ) {
    return `
  <table
      style="width: 600px; margin: 0 auto; padding: 0; font-family: verdana"
      align="center"
      border="0"
      cellpadding="0"
      cellspacing="0"
      id="bodyTable"
  >
    <tr>
      <td valign="top" style="background-color: #ffffff; border-radius: 6px">
        <table
          style="width: 600px; margin: 0 auto; padding: 0; font-family: verdana"
          align="center"
          border="0"
          cellpadding="0"
          cellspacing="0"
        >
          <tr>
            <td
              colspan="2"
              valign="top"
              align="left"
              style="
                text-align: left;
                padding: 26px 26px;
                font-family: verdana;
                font-size: 18px;
                color: #192f41;
                font-weight: bold;
              "
            >
              ${eventName} is assigned for your review
            </td>
          </tr>
          <tr>
            <td
              valign="top"
              align="left"
              style="
                width: 300px;
                text-align: left;
                padding: 0 26px;
                font-family: verdana;
                font-size: 16px;
                color: #192f41;
                font-weight: normal;
              "
            >
              ${workflowTemplateName} status
            </td>
            <td
              valign="top"
              align="left"
              style="
                text-align: left;
                padding: 0 0;
                font-family: verdana;
                font-size: 14px;
                color: #192f41;
                font-weight: normal;
              "
            >
              <span
                class="dot"
                style="
                  background-color: #1b5e20;
                  width: 8px;
                  height: 8px;
                  border-radius: 50%;
                  display: inline-block;
                "
              ></span>
              In Progress
            </td>
          </tr>
          <tr>
            <td
              colspan="2"
              valign="top"
              align="left"
              style="
                text-align: left;
                padding: 26px 26px;
                font-family: verdana;
                font-size: 15px;
                color: #192f41;
                font-weight: bold;
              "
            >
              <span
                style="
                  background-color: #e3f7e5;
                  border-radius: 4px;
                  padding: 10px;
                "
                >${stageName}</span
              >
            </td>
          </tr>
          <tr>
            <td
              colspan="2"
              valign="top"
              align="center"
              style="
                text-align: center;
                padding: 26px 26px;
                font-family: verdana;
                font-size: 15px;
                color: #ffffff;
                font-weight: bold;
              "
            >
              <a
                href="${url}"
                style="background-color: #192F41; border-radius: 4px; padding:10px 15px; color: #fff; text-decoration: none;"
                >View in PR1</a
              >
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>`;
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }
}
