<div fxLayout="row" fxLayoutAlign="space-between">
  <h1>Offer Management</h1>
  <button mat-stroked-button type="button" (click)="onCancel()">Close</button>
</div>
<div *ngIf="event" fxLayout="row" fxLayoutAlign="space-between" fxLayout.lt-md="column"
  fxLayoutAlign.lt-md="start start">
  <h2>{{event.EventName}}</h2>
</div>
<div fxLayout="row" fxLayoutAlign="space-between">
  <h2>Create Offer Group</h2>
</div>
<div fxLayout="row" fxLayoutAlign="space-between">
  <p>Filter to begin selecting Offers</p>
</div>

<form [formGroup]="formGroup" novalidate>
  <div fxLayout="row" fxLayoutGap="16px" fxLayout.lt-md="column" fxLayoutGap.lt-md="4px">
    <mat-form-field appearance="outline" fxFlex>
      <mat-label>Version</mat-label>
      <input matInput readonly formControlName="version" />
      <span class="input-btn" matSuffix (click)="openVersionsDialog()">Edit</span>
      <mat-error *ngIf="formGroup.controls.version.hasError('required')">
        Version is required
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" fxFlex>
      <mat-label>Offer Tags</mat-label>
      <input matInput readonly formControlName="offerTags" />
      <span class="input-btn" matSuffix (click)="openOfferTagsDialog()">Edit</span>
    </mat-form-field>
  </div>
</form>

<ng-container *ngIf="selectedVersions.length">
  <div *ngIf="viewModel$ | async as viewModel">
    <mat-card appearance="outlined">
      <mat-card-content fxLayout="column">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px" fxLayout.lt-md="column"
          fxLayoutAlign.lt-md="start start" fxLayoutGap.lt-md="4px">
          <h2>Add Offers</h2>
          <mat-form-field appearance="outline" class="mat-form-field-no-padding">
            <mat-icon matPrefix>search</mat-icon>
            <mat-label>Search Offers</mat-label>
            <input matInput [formControl]="search" type="text" (input)="onSearch($event)" placeholder="Search"
              #searchInput data-testid="Search Bar" />
            <button type="button" matSuffix mat-icon-button aria-label="Clear" *ngIf="searchInput.value"
              (click)="clearSearch()">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <span class="menu-spacer"></span>

          <!--only display the sort by dropdown for the cards view(table view sorts on columns)-->

          <mat-button-toggle-group #group="matButtonToggleGroup" value={{viewModel.viewMode}} aria-label="View mode">
            <mat-button-toggle value="CARDS" matTooltip="Card view" aria-label="Card view"
              (click)="toggleView('CARDS')">
              <mat-icon>grid_view</mat-icon>
            </mat-button-toggle>
            <mat-button-toggle value="TABLE" matTooltip="Table view" aria-label="Table view"
              (click)="toggleView('TABLE')">
              <mat-icon>table_view</mat-icon>
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>

        <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="16px" fxLayout.lt-md="column"
          fxLayoutAlign.lt-md="start start" fxLayoutGap.lt-md="4px">
          <div style="width: 75%;">
            <div class="grid-container">
              <ng-container *ngIf="viewModel.viewMode === 'CARDS'">
                <mat-checkbox [checked]="isAllSelected()" [indeterminate]="isSomeSelectedOnPage() && !isAllSelected()"
                  (change)="masterToggle($event.checked)" [disabled]="viewModel.offers.length === 0">Select
                  All</mat-checkbox>
                <div class="grid" *ngIf="viewModel.offers.length">
                  <mat-card appearance="outlined" *ngFor="let OfferDomain of viewModel.offers; index as i">
                    <mat-card-content>
                      <div class="grid-col-span-2 offer-name" title="{{OfferDomain.Detail.Name }}" fxLayout="row wrap"
                        fxLayoutAlign="start start" fxLayoutGap="15px">
                        <mat-checkbox (click)="$event.stopPropagation()" fxFlex="30px" class="card-checkbox"
                          (change)="toggleSelect($event.checked, OfferDomain)"
                          [checked]="selection.isSelected(OfferDomain)" [disabled]="isOfferSelected(OfferDomain)">
                        </mat-checkbox>
                      </div>
                      <div fxLayoutAlign="center center" class="offer-image">
                        <img mat-card-md-image *ngIf="OfferDomain.FacingAsset" [src]="getImageSrc(OfferDomain)"
                          alt="Offer image" (error)="onImageError($event)" />
                        <img mat-card-md-image *ngIf="!OfferDomain.FacingAsset" src="./assets/images/default-image.png"
                          alt="Offer image" />
                      </div>
                      <div class="offer-content" fxLayout="column">
                        <div class="offer-headline">
                          <div class="ellipsis-3-line offer-headline-bold">{{ OfferDomain.Detail.Headline }}</div>
                        </div>
                        <div class="offer-bodycopy">
                          <div class="ellipsis-3-line">{{ OfferDomain.Detail.BodyCopy }}</div>
                        </div>
                      </div>

                    </mat-card-content>
                  </mat-card>
                </div>
                <h2 *ngIf="!viewModel.offers.length">No Records Found</h2>
              </ng-container>

              <ng-container *ngIf="viewModel.viewMode === 'TABLE'">
                <table *ngIf="viewModel.offers.length" mat-table [dataSource]="offersListData" matSort
                  (matSortChange)="onSort($event)" matSortDisableClear [matSortStart]="'desc'" class="mat-elevation-z8">

                  <ng-container matColumnDef="select">
                    <th class="no-ellipsis" mat-header-cell *matHeaderCellDef>
                      <mat-checkbox [checked]="isAllSelected()"
                        [indeterminate]="isSomeSelectedOnPage() && !isAllSelected()"
                        (change)="masterToggle($event.checked)" [disabled]="viewModel.offers.length === 0">
                      </mat-checkbox>
                    </th>
                    <td class="no-ellipsis" mat-cell *matCellDef="let row">
                      <mat-checkbox (click)="$event.stopPropagation()" (change)="toggleSelect($event.checked, row)"
                        [checked]="selection.isSelected(row)" [disabled]="isOfferSelected(row)"></mat-checkbox>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="Image">
                    <th mat-header-cell *matHeaderCellDef [resizeColumn]="true">Preview</th>
                    <td mat-cell *matCellDef="let record">
                      <img mat-card-md-image *ngIf="record.FacingAsset" [src]="getImageSrc(record)" alt="Offer image"
                        (error)="onImageError($event)">
                      <img mat-card-md-image *ngIf="!record.FacingAsset" src="./assets/images/default-image.png"
                        alt="Offer image">
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="Id">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Id"
                      [resizeColumn]="true">
                      Id
                    </th>
                    <td mat-cell *matCellDef="let record">{{ record.Detail.Id }}</td>
                  </ng-container>

                  <ng-container matColumnDef="Name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Name"
                      [resizeColumn]="true">
                      Offer Name
                    </th>
                    <td mat-cell *matCellDef="let record"> {{ record.Detail.Name }}</td>
                  </ng-container>

                  <ng-container matColumnDef="ClientKey">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by ClientKey"
                      [resizeColumn]="true">
                      Client Key
                    </th>
                    <td mat-cell *matCellDef="let record">{{ record.Detail.ClientKey }}</td>
                  </ng-container>

                  <ng-container matColumnDef="Headline">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Headline"
                      [resizeColumn]="true">
                      Headline
                    </th>
                    <td mat-cell *matCellDef="let record">{{ record.Detail.Headline }}</td>
                  </ng-container>

                  <ng-container matColumnDef="BodyCopy">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by BodyCopy"
                      [resizeColumn]="true">
                      BodyCopy
                    </th>
                    <td mat-cell *matCellDef="let record">{{ record.Detail.BodyCopy }}</td>
                  </ng-container>

                  <ng-container matColumnDef="Rank">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Rank"
                      [resizeColumn]="true">
                      Rank
                    </th>
                    <td mat-cell *matCellDef="let record">{{ record.Detail.Rank }}</td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>

                <table *ngIf="!viewModel.offers.length">
                  <tr>
                    <td class="no-records" [attr.colspan]="displayedColumns.length">
                      <h2>No Records Found</h2>
                    </td>
                  </tr>
                </table>
              </ng-container>
            </div>
            <mat-paginator [length]="viewModel.totalRecords" [pageSize]="viewModel.page.pageSize"
              [pageSizeOptions]="[5, 10, 20, 50, 100,500]" (page)="onPage($event)" [hidden]="!viewModel.offers.length"
              showFirstLastButtons [pageIndex]="viewModel.page.pageIndex">
            </mat-paginator>

            <mat-card-actions fxLayout="row" fxLayoutAlign="end">
              <div class="btns" fxLayout="row" fxLayoutAlign="end start" fxLayoutGap="16px">
                <button mat-stroked-button type="button" (click)="onCancel()">Cancel</button>
                <button mat-raised-button type="button" color="accent" (click)="addToNewOfferGroup()"
                  [disabled]="!isSelectionChanged">Add to Group</button>
              </div>
            </mat-card-actions>
          </div>
          <div>
            <mat-card appearance="outlined" class="card">
              <mat-card-header class="header" color="accent">
                <mat-card-title class="title">New Offer Group</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <div class="noOffers" *ngIf="!offerGroupOffers.length">
                  <b>Nothing here yet</b>
                  <p class="p">Select Offers and add to group</p>
                </div>

                <div class="offerGroupOffers" *ngIf="offerGroupOffers.length">
                  <div cdkDropList class="example-list" (cdkDropListDropped)="offerGroupOfferDrop($event)">
                    <div class="example-box" *ngFor="let OfferDomain of offerGroupOffers; index as i" cdkDrag>
                      <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
                      <mat-card>
                        <mat-card-content class="mat-card-content">
                          <div fxLayoutAlign="start center" fxLayout="row wrap" style="margin-top: -17px;">
                            {{i + 1}}
                            <span class="rank">
                              <button mat-icon-button (click)="removefromOfferGroup(OfferDomain)">
                                <mat-icon>cancel</mat-icon>
                              </button></span>
                            <img class="offer-image" mat-card-md-image *ngIf="OfferDomain.FacingAsset"
                              [src]="getImageSrc(OfferDomain)" alt="Offer image" (error)="onImageError($event)">
                            <img mat-card-md-image *ngIf="!OfferDomain.FacingAsset"
                              src="./assets/images/default-image.png" alt="Offer image">
                            <div class="offer-content" fxLayout="column">
                              <div class="offer-headline">
                                <div class="ellipsis-3-line offer-headline-bold">{{ OfferDomain.Detail.Headline }}</div>
                              </div>
                              <div class="offer-bodycopy">
                                <div class="ellipsis-3-line">{{ OfferDomain.Detail.BodyCopy }}</div>
                              </div>
                            </div>
                          </div>
                        </mat-card-content>
                      </mat-card>
                    </div>
                  </div>
                </div>
              </mat-card-content>

              <mat-card-actions *ngIf="offerGroupOffers.length" fxLayout="row" fxLayoutAlign="center">
                <div fxLayout="row" fxLayoutAlign="end center">
                  <button class="fixed-btn" mat-raised-button type="button" color="accent" (click)="saveOfferGroup()">
                    Save Group
                  </button>
                </div>
              </mat-card-actions>
            </mat-card>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</ng-container>

<mat-tab-nav-panel #tabPanel>
  <router-outlet></router-outlet>
</mat-tab-nav-panel>