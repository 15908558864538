import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, tap, map, Subscription, startWith, take } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Logger, ConfirmDialogComponent, UtilityService, PreviewMode, OrganizationService, JunctionAiService, } from 'src/app/@shared';
import { Offer, OfferDomain, OfferService, OfferTag, OfferTagService, OfferPromoService, OfferPromo, PromoDomain, AssetTagService, AssetTag, AssetGroup, EventService, Event, ProductCategoryService, ProductCategory, MontageBuilderService, } from '../..';
import { DEFAULT_SNACKBAR_CONFIG } from 'src/app/@shared/constants/site.constants';
import { Status } from '../../models/offer-promo-status';
import * as dayjs from 'dayjs';
import { AuthorizeControlService, CommentsComponent } from 'pr1-ui-components';
import { MatSelect } from '@angular/material/select';
import { SettingsService } from '../../services/settings.service';

const log = new Logger('OfferDetailsComponent');

@Component({
  selector: 'app-offer-details',
  templateUrl: './offer-details.component.html',
  styleUrls: ['./offer-details.component.scss'],
})
export class OfferDetailsComponent<TOfferDomain extends OfferDomain<Offer>, TOfferPromo extends OfferPromo, TEvent extends Event, TPromoDomain extends PromoDomain<OfferPromo>, TProductCategory extends ProductCategory,> implements OnInit, OnDestroy {
  offer$: Observable<OfferDomain<Offer>> = of({} as OfferDomain<Offer>);
  assetsforBadge: any;
  assetGroups = [];
  offertypes: any;
  noneValue = null;
  private versionName = '';
  private prevHeadline = '';
  private prevOfferBodyCopy = '';
  public showJunctionAi?: boolean = false;
  tagCtrl = new FormControl<string>('', [
    Validators.minLength(1),
    Validators.maxLength(50),
  ]);
  offerTagIds: string[] = [];
  updatedOfferTagIds: string[] = [];
  searchCount: number = 0;
  formGroup = new FormGroup({
    Detail: new FormGroup({
      ClientKey: new FormControl<string>('', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(200),
      ]),
      Id: new FormControl<string>(
        { value: '', disabled: true },
        { nonNullable: true }
      ),
      StartDate: new FormControl<Date | string | null>(null, [
        Validators.required,
      ]), // Validators.pattern("[0-9 \\-/]*"
      EndDate: new FormControl<Date | string | null>(null, [
        Validators.required,
      ]), // Validators.pattern("[0-9 \\-/]*"
      Name: new FormControl<string>('', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(100),
      ]),
      Headline: new FormControl<string>(''),
      BodyCopy: new FormControl<string>(''),
      Disclaimer: new FormControl<string>(''),
      ClientComment: new FormControl<string>(''),
      SalePrice: new FormControl<number>(0, [
        Validators.min(0),
        Validators.max(9999999),
      ]),
      MaxSave: new FormControl<number>(0, [
        Validators.min(0),
        Validators.max(9999999),
      ]),
      MinSave: new FormControl<number>(0, [
        Validators.min(0),
        Validators.max(9999999),
      ]),
      PercentAmount: new FormControl<number>(0, [
        Validators.min(0),
        Validators.max(99),
      ]),
      SavePercent: new FormControl<number>(0, [
        Validators.min(0),
        Validators.max(99),
      ]),
      SaveStandard: new FormControl<number>(0, [
        Validators.min(0),
        Validators.max(9999999),
      ]),
      SpendAmount: new FormControl<number>(0, [
        Validators.min(0),
        Validators.max(9999999),
      ]),
      AndUp: new FormControl<string>(''),
      TargetURL: new FormControl<string>('', [
        Validators.minLength(1),
        Validators.maxLength(5000),
      ]),
      Rank: new FormControl<number>(1, [
        Validators.min(1),
        Validators.max(9999999),
      ]),
      AdRetailPrice: new FormControl<number>(0, [
        Validators.min(0),
        Validators.max(9999999),
      ]),
      UnitOfMeasure: new FormControl<string>('', [
        Validators.minLength(1),
        Validators.maxLength(9999999),
      ]),
      Quantity: new FormControl<number>(0, [
        Validators.min(0),
        Validators.max(99),
      ]),
      PercentOff: new FormControl<number>(0, [
        Validators.min(0),
        Validators.max(99),
      ]),
      DollarOff: new FormControl<number>(0, [
        Validators.min(0),
        Validators.max(9999999),
      ]),
      BuyQuantity: new FormControl<number>(0, [
        Validators.min(0),
        Validators.max(99),
      ]),
      GetQuantity: new FormControl<number>(0, [
        Validators.min(0),
        Validators.max(99),
      ]),
      Limit: new FormControl<string>('', [
        Validators.minLength(1),
        Validators.maxLength(100),
        Validators.pattern("[a-zA-Z0-9 \\-#&*\\'/.,;]*"),
      ]),
      WhenYouBuy: new FormControl<number>(0, [
        Validators.min(0),
        Validators.max(99),
      ]),
      SaveAmount: new FormControl<number>(0, [
        Validators.min(0),
        Validators.max(9999999),
      ]),
      RewardAmount: new FormControl<number>(0, [
        Validators.min(0),
        Validators.max(9999),
      ]),
      RewardQuantity: new FormControl<number>(0, [
        Validators.min(0),
        Validators.max(9999),
      ]),
      PageNumber: new FormControl<string>('', [
        Validators.pattern("[a-zA-Z0-9]*")
      ]),
      Position: new FormControl<number | null>(null, [
        Validators.min(1),
        Validators.max(9999)
      ]),
      PrintPageId: new FormControl<string | null>(null),
      RewardSpend: new FormControl<number>(0, [
        Validators.min(0),
        Validators.max(9999),
      ]),
      AdditionalDiscountAmount: new FormControl<number>(0, [
        Validators.min(0),
        Validators.max(9999),
      ]),
      AdditionalDiscountQuantity: new FormControl<number>(0, [
        Validators.min(0),
        Validators.max(9999),
      ]),
      AdditionalDiscountAmount2: new FormControl<number>(0, [
        Validators.min(0),
        Validators.max(9999),
      ]),
      AdditionalDiscountQuantity2: new FormControl<number>(0, [
        Validators.min(0),
        Validators.max(9999),
      ]),
      AdditionalDiscountAmount3: new FormControl<number>(0, [
        Validators.min(0),
        Validators.max(9999),
      ]),
      AdditionalDiscountQuantity3: new FormControl<number>(0, [
        Validators.min(0),
        Validators.max(9999),
      ]),
      WithLoyaltyMembership: new FormControl<boolean>(true, [
        Validators.required,
      ]),
      OfferVariantName: new FormControl<string | null>(null),
      CustomPricingText: new FormControl<string | null>(null),
      BadgeAssetId: new FormControl<string | null>(null),
      OfferTypeId: new FormControl<string | null>(null),
      Status: new FormControl<string>('PENDING'),
      ProductCategoryIds: new FormControl<any[]>([]),
    }),
  });
  categoryCtrl = new FormControl('');
  offerServiceSubs = new Subscription();
  offerId: string = '0';
  eventId!: string;
  isRun = false;
  currentJAICreativeDetail: any;
  isSucceded = false;
  jaiCreativeAdded = false;
  maxDate: Date = new Date(9999, 0, 1);
  private offerResponse: any;
  offerpromosList: TPromoDomain[] = [];
  versionsCount: number = 0;
  readOnlyMode: any = null;
  routeQueryParams: any = {};
  isCommentDialogOpened: boolean = false;
  montageBuilderCheckConfigureStatus: boolean = false;
  PreviewMode = PreviewMode;
  category?: string = '';
  productCategories$: any[] = [] as any[];
  filteredCategory!: Observable<any[]>;
  private selectedItems: any = [];
  public adminSettingStatus: boolean = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public offerService: OfferService<Offer, TOfferDomain>,
    private offerTagService: OfferTagService<OfferTag>,
    private offerpromoService: OfferPromoService<TOfferPromo, TPromoDomain>,
    private eventService: EventService<TEvent>,
    private matSnackBar: MatSnackBar,
    private assetTagService: AssetTagService<AssetTag, AssetGroup>,
    private dialog: MatDialog,
    private authorizationService: AuthorizeControlService,
    private cdRef: ChangeDetectorRef,
    private montageBuilderService: MontageBuilderService,
    public utilityService: UtilityService,
    private categoryService: ProductCategoryService<TProductCategory>,
    private orgService: OrganizationService,
    private junctionAiService: JunctionAiService,
    private settingService: SettingsService
  ) { }

  ngOnInit(): void {
    this.offerpromoService.commentDialogBoxStatus$.subscribe((status: boolean) => { this.isCommentDialogOpened = status });
    this.offerpromoService.eventId = this.route.snapshot.params['eventId'];

    this.route.params.subscribe((params) => {
      // get the offer id from the parent route
      this.offerId = params['offerId'];
      this.eventId = params['eventId'];
      this.montageBuilderService.montageBuilderStatusBehaviorSubject.next(false);
      this.montageBuilderService.montageBuilderResponseBehaviorSubject.next({});
      // if we are editing a record, get the record data
      this.offerService.getAssets().subscribe((res: any) => {
        this.assetsforBadge = res.value;
      });
      this.offerService.getOfferTypes().subscribe((res: any) => {
        this.offertypes = res.value;
      });
      this.categoryService.getAllCategories().subscribe({
        next: (data: any) => {
          this.productCategories$ = data.value;
          this.categoryCtrl.patchValue('');
          this.getDataById();
        }
      });

      this.offerService.saveorCancel$.subscribe((res) => {
        if (res === '1') {
          this.save();
        } else if (res === '0') {
          this.cancel();
        }
      });
      this.offerServiceSubs.add(this.offerService.newVersion$.subscribe((res) => {
        if (res && res != this.versionName) {
          this.versionName = res;
          this.getDataById();
        }
      }));

      this.offerServiceSubs.add(
        this.offerService.versionsCount$.subscribe((res) => {
          this.versionsCount = res;
        }));

      this.readOnlyMode = this.offerpromoService.getOfferDetailsReadOnlyValue();
      this.offerpromoService.offerDetailsReadOnlyMode$.subscribe((res) => {
        this.readOnlyMode = res;
      });
    });

    this.filteredCategory = this.categoryCtrl.valueChanges.pipe(
      startWith(''),
      map((category) =>
        category ? this.filterCategory(category) : this.productCategories$.slice()
      )
    );
    this.getAdminSettingInfo();
  }

  clearData() {
    this.categoryCtrl.patchValue('');
  }

  getAdminSettingInfo() {
    this.settingService.getIntegrationSearch('aiautomation', 'offer').subscribe(res => {
      if (res) {
        this.adminSettingStatus = res.Status;
      }
    });
  }

  filterCategory(name: string) {
    let arr = this.productCategories$.filter(
      (category) => category.ProductCategoryName.toLowerCase().indexOf(name.toLowerCase()) === 0
    );

    return arr.length ? arr : [];
  }

  ngAfterContentChecked() {
    this.cdRef.detectChanges();
  }

  onRun() {
    this.searchCount = 0;
    this.offerpromoService.createJAICreative(this.offerId).subscribe((res: any) => {
      if (res) {
        this.getJAICreative(res.Id);
        this.showJunctionAi = false;
      }
    });
  }

  getJAICreative(id: string) {
    this.jaiCreativeAdded = false;
    if (this.searchCount > 5) {
      return;
    }
    this.searchCount++;
    this.offerpromoService.getJAICreative(id).subscribe((creativeDetails: any) => {
      if (creativeDetails && (creativeDetails.BodyCopy || creativeDetails.Headline || this.searchCount == 5)) {
        this.updateDetails(creativeDetails, true);
      } else {
        setTimeout(() => {
          if (this.searchCount <= 5) {
            this.getJAICreative(id);
          }
        }, 5000);
      }
    }, err => {
      if (this.searchCount <= 5) {
        this.getJAICreative(id);
      }
    });
  }

  updateDetails(creativeDetails: any, isRun: boolean) {
    this.prevHeadline = creativeDetails.Headline;
    this.prevOfferBodyCopy = creativeDetails.BodyCopy;
    this.formGroup.controls.Detail.controls.Headline.patchValue(creativeDetails.Headline);
    this.formGroup.controls.Detail.controls.BodyCopy.patchValue(creativeDetails.BodyCopy);
    this.currentJAICreativeDetail = creativeDetails;
    this.isRun = isRun;
    if (creativeDetails.status != 'Completed') {
      this.showJunctionAi = false;
    }
  }

  applyChanges() {
    this.isRun = false;
    this.isSucceded = true;
    this.jaiCreativeAdded = true;
    setTimeout(() => {
      this.isSucceded = false;
    }, 5000);
  }

  revertChanges() {
    this.formGroup.controls.Detail.controls.Headline.patchValue(this.prevHeadline);
    this.formGroup.controls.Detail.controls.BodyCopy.patchValue(this.prevOfferBodyCopy);
    this.isRun = false;
  }

  navigateChildOffers() {
    this.router.navigate([`../`, `childversions`], {
      relativeTo: this.route,
      queryParamsHandling: 'preserve'
    });
  }

  getDataById() {
    this.showJunctionAi = false;
    this.updateDetails({}, false);
    if (this.offerId && this.offerId !== '0') {
      this.offerTagIds = [];
      this.updatedOfferTagIds = [];
      this.offer$ = this.offerService.getOffer(this.offerId).pipe(
        tap((offer) => {
          if (this.canEdit() && offer.JAICreativeId) {
            this.searchCount = 0;
            this.getJAICreative(offer.JAICreativeId);
          } else {
            this.showJunctionAi = offer.OfferHasProducts;
          }
          let responseOfferDetails = JSON.parse(JSON.stringify(offer));
          this.montageBuilderService.selectedOfferDetailsBehaviorSubject.next(offer);
          if (responseOfferDetails && responseOfferDetails.ImageEditorConfigId) {
            this.montageBuilderService.montageBuilderCheckConfigureBehaviorSubject.next(true);
            this.montageBuilderService.montageBuilderResponseBehaviorSubject.next(offer);
          } else {
            this.montageBuilderService.montageBuilderCheckConfigureBehaviorSubject.next(false);
            this.montageBuilderService.montageBuilderResponseBehaviorSubject.next(offer);
          }


          this.offerServiceSubs.add(this.montageBuilderService.montageBuilderCheckConfigure$.pipe(take(1)).subscribe((data) => {
            this.montageBuilderCheckConfigureStatus = data;
          }));

          this.offerService.currentRecord = JSON.parse(JSON.stringify(offer));
          if (offer && offer.Detail && offer.Detail.StartDate) {
            offer.Detail.StartDate = new Date(offer.Detail.StartDate);
            console.log('Start Date', offer.Detail.StartDate);
          }
          if (offer && offer.Detail && offer.Detail.EndDate) {
            offer.Detail.EndDate = new Date(offer.Detail.EndDate);
            console.log('End Date', offer.Detail.EndDate);
          }
          const newVersionName = this.offerService.getNewVersion;
          if (newVersionName) {
            this.offerService.getVarintClientKey(this.eventId, this.offerId, newVersionName).subscribe((res) => {
              this.formGroup.controls.Detail.controls.ClientKey.patchValue(res.value)
            })

            offer.Detail.OfferVariantName = newVersionName;
          }
          this.formGroup.patchValue(offer);
          this.setNulls();
          this.formGroup.controls.Detail.controls.BadgeAssetId.patchValue(
            offer?.BadgeAssetId as string
          );
          this.formGroup.controls.Detail.controls.OfferTypeId.patchValue(
            offer?.OfferTypeId as string
          );
          this.formGroup.controls.Detail.controls.PrintPageId.patchValue(
            offer?.PrintPageId as string
          );
          this.formGroup.controls.Detail.controls.PageNumber.patchValue(
            offer?.PageNumber as string
          );
          this.formGroup.controls.Detail.controls.Position.patchValue(
            offer?.Position as number
          );
          if (offer.Position == -1) {
            this.formGroup.controls.Detail.controls.Position.patchValue(
              null
            );
          }

          this.formGroup.markAllAsTouched();
          this.offerResponse = offer;

          if (offer.OfferTagIds && offer.OfferTagIds.length) {
            this.offerTagIds = offer.OfferTagIds;
            this.updatedOfferTagIds = offer.OfferTagIds;
          }
          if (offer.ProductCategoryIds && offer.ProductCategoryIds.length > 0) {
            let categories = this.productCategories$.filter((x: any) => offer.ProductCategoryIds?.includes(x.Id));
            this.formGroup.controls.Detail.controls.ProductCategoryIds.patchValue(categories);
            this.selectedItems = categories;
          }
        })
      );
    } else {
      this.eventService.getEventDomain(this.eventId).subscribe((res) => {
        this.formGroup.controls.Detail.controls.StartDate.patchValue(
          new Date(res?.Detail.StartDate as any)
        );
        this.formGroup.controls.Detail.controls.EndDate.patchValue(
          new Date(res?.Detail.EndDate as any)
        );
      });
      this.offerService.getBaseClientKey(this.eventId).subscribe((res) => {
        this.formGroup.controls.Detail.controls.ClientKey.patchValue(res.value);
      })
    }
  }

  updateSelectedItems(matSelect: MatSelect, category: any) {
    if (!this.selectedItems) this.selectedItems = [];
    const checkedItems = matSelect.options.filter(x => x.selected).map(i => i.value);
    if (checkedItems && checkedItems.length > 0) {
      if (checkedItems.find(x => x.Id === category.Id)) {
        this.selectedItems.push(category);
      } else {
        this.selectedItems = this.selectedItems.filter((i: any) => i.Id !== category.Id);
      }
    } else {
      this.selectedItems = this.selectedItems.filter((i: any) => i.Id !== category.Id);
    }
  }

  validateDate(): boolean {
    let startDate = this.formGroup.controls.Detail.controls.StartDate;
    let endDate = this.formGroup.controls.Detail.controls.EndDate;
    if (endDate && endDate.value && startDate && startDate.value) {
      if (endDate.value <= startDate.value) {
        this.matSnackBar.open(
          'End Date must be greater than Start Date',
          'Error',
          { verticalPosition: 'top', panelClass: ['snackbar-error'] }
        );
        return false;
      }
    }
    return true;
  }

  save() {
    this.saveOffer();
  }

  clearNulls(offer: OfferDomain<Offer>): any {
    if (offer.Detail.AdRetailPrice == null) offer.Detail.AdRetailPrice = -1;
    if (offer.Detail.SalePrice == null) offer.Detail.SalePrice = -1;
    if (offer.Detail.MaxSave == null) offer.Detail.MaxSave = -1;
    if (offer.Detail.MinSave == null) offer.Detail.MinSave = -1;
    if (offer.Detail.PercentAmount == null) offer.Detail.PercentAmount = -1;
    if (offer.Detail.SavePercent == null) offer.Detail.SavePercent = -1;
    if (offer.Detail.SaveStandard == null) offer.Detail.SaveStandard = -1;
    if (offer.Detail.SpendAmount == null) offer.Detail.SpendAmount = -1;
    if (offer.Detail.Quantity == null) offer.Detail.Quantity = -1;
    if (offer.Detail.PercentOff == null) offer.Detail.PercentOff = -1;
    if (offer.Detail.DollarOff == null) offer.Detail.DollarOff = -1;
    if (offer.Detail.BuyQuantity == null) offer.Detail.BuyQuantity = -1;
    if (offer.Detail.GetQuantity == null) offer.Detail.GetQuantity = -1;
    if (offer.Detail.WhenYouBuy == null) offer.Detail.WhenYouBuy = -1;
    if (offer.Detail.SaveAmount == null) offer.Detail.SaveAmount = -1;
    if (offer.Detail.RewardAmount == null) offer.Detail.RewardAmount = 0;
    if (offer.Detail.RewardQuantity == null) offer.Detail.RewardQuantity = 0;
    if (offer.Detail.RewardSpend == null) offer.Detail.RewardSpend = 0;
    if (offer.Detail.AdditionalDiscountAmount == null)
      offer.Detail.AdditionalDiscountAmount = -1;
    if (offer.Detail.AdditionalDiscountQuantity == null)
      offer.Detail.AdditionalDiscountQuantity = -1;
    if (offer.Detail.AdditionalDiscountAmount2 == null)
      offer.Detail.AdditionalDiscountAmount2 = -1;
    if (offer.Detail.AdditionalDiscountQuantity2 == null)
      offer.Detail.AdditionalDiscountQuantity2 = -1;
    if (offer.Detail.AdditionalDiscountAmount3 == null)
      offer.Detail.AdditionalDiscountAmount3 = -1;
    if (offer.Detail.AdditionalDiscountQuantity3 == null)
      offer.Detail.AdditionalDiscountQuantity3 = -1;
    if (offer.Detail.Position == null)
      offer.Detail.Position = -1;

    return offer;
  }

  setNulls(): any {
    if (
      this.formGroup.controls.Detail.controls.AdRetailPrice.getRawValue() == -1
    )
      this.formGroup.controls.Detail.controls.AdRetailPrice.setValue(null);
    if (this.formGroup.controls.Detail.controls.SalePrice.getRawValue() == -1)
      this.formGroup.controls.Detail.controls.SalePrice.setValue(null);
    if (this.formGroup.controls.Detail.controls.MaxSave.getRawValue() == -1)
      this.formGroup.controls.Detail.controls.MaxSave.setValue(null);
    if (this.formGroup.controls.Detail.controls.MinSave.getRawValue() == -1)
      this.formGroup.controls.Detail.controls.MinSave.setValue(null);
    if (this.formGroup.controls.Detail.controls.SavePercent.getRawValue() == -1)
      this.formGroup.controls.Detail.controls.SavePercent.setValue(null);
    if (this.formGroup.controls.Detail.controls.PercentAmount.getRawValue() == -1)
      this.formGroup.controls.Detail.controls.PercentAmount.setValue(null);
    if (this.formGroup.controls.Detail.controls.SaveStandard.getRawValue() == -1)
      this.formGroup.controls.Detail.controls.SaveStandard.setValue(null);
    if (this.formGroup.controls.Detail.controls.SpendAmount.getRawValue() == -1)
      this.formGroup.controls.Detail.controls.SpendAmount.setValue(null);
    if (this.formGroup.controls.Detail.controls.Quantity.getRawValue() == -1)
      this.formGroup.controls.Detail.controls.Quantity.setValue(null);
    if (this.formGroup.controls.Detail.controls.PercentOff.getRawValue() == -1)
      this.formGroup.controls.Detail.controls.PercentOff.setValue(null);
    if (this.formGroup.controls.Detail.controls.DollarOff.getRawValue() == -1)
      this.formGroup.controls.Detail.controls.DollarOff.setValue(null);
    if (this.formGroup.controls.Detail.controls.BuyQuantity.getRawValue() == -1)
      this.formGroup.controls.Detail.controls.BuyQuantity.setValue(null);
    if (this.formGroup.controls.Detail.controls.GetQuantity.getRawValue() == -1)
      this.formGroup.controls.Detail.controls.GetQuantity.setValue(null);
    if (this.formGroup.controls.Detail.controls.WhenYouBuy.getRawValue() == -1)
      this.formGroup.controls.Detail.controls.WhenYouBuy.setValue(null);
    if (this.formGroup.controls.Detail.controls.SaveAmount.getRawValue() == -1)
      this.formGroup.controls.Detail.controls.SaveAmount.setValue(null);
    if (
      this.formGroup.controls.Detail.controls.RewardAmount.getRawValue() == -1
    )
      this.formGroup.controls.Detail.controls.RewardAmount.setValue(null);
    if (
      this.formGroup.controls.Detail.controls.RewardQuantity.getRawValue() == -1
    )
      this.formGroup.controls.Detail.controls.RewardQuantity.setValue(null);
    if (this.formGroup.controls.Detail.controls.RewardSpend.getRawValue() == -1)
      this.formGroup.controls.Detail.controls.RewardSpend.setValue(null);
    if (
      this.formGroup.controls.Detail.controls.AdditionalDiscountAmount.getRawValue() ==
      -1
    )
      this.formGroup.controls.Detail.controls.AdditionalDiscountAmount.setValue(
        null
      );
    if (
      this.formGroup.controls.Detail.controls.AdditionalDiscountQuantity.getRawValue() ==
      -1
    )
      this.formGroup.controls.Detail.controls.AdditionalDiscountQuantity.setValue(
        null
      );
    if (
      this.formGroup.controls.Detail.controls.AdditionalDiscountAmount2.getRawValue() ==
      -1
    )
      this.formGroup.controls.Detail.controls.AdditionalDiscountAmount2.setValue(
        null
      );
    if (
      this.formGroup.controls.Detail.controls.AdditionalDiscountQuantity2.getRawValue() ==
      -1
    )
      this.formGroup.controls.Detail.controls.AdditionalDiscountQuantity2.setValue(
        null
      );
    if (
      this.formGroup.controls.Detail.controls.AdditionalDiscountAmount3.getRawValue() ==
      -1
    )
      this.formGroup.controls.Detail.controls.AdditionalDiscountAmount3.setValue(
        null
      );
    if (
      this.formGroup.controls.Detail.controls.AdditionalDiscountQuantity3.getRawValue() ==
      -1
    )
      this.formGroup.controls.Detail.controls.AdditionalDiscountQuantity3.setValue(
        null
      );
    if (
      this.formGroup.controls.Detail.controls.Position.getRawValue() ==
      -1
    )
      this.formGroup.controls.Detail.controls.Position.setValue(
        null
      );
  }

  trimControlValues(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach((key: string) => {
      const abstractControl = formGroup.get(key);
      if (abstractControl instanceof FormGroup) {
        this.trimControlValues(abstractControl);
      } else {
        if (typeof abstractControl?.value == 'string') {
          abstractControl.setValue(abstractControl?.value.trim());
        }
      }
    });
  }

  formatDate(date: Date | string | null) {
    if (date) {
      const newDate = dayjs(date).format('MM/DD/YYYY');
      return newDate;
    } else {
      return '';
    }
  }

  saveOffer() {
    this.trimControlValues(this.formGroup);

    this.formGroup.markAsDirty();
    this.formGroup.markAllAsTouched();
    if (this.formGroup.valid) {
      if (!this.validateDate()) return;

      let offer = this.formGroup.getRawValue() as OfferDomain<Offer>;
      const startDate = this.formatDate(
        this.formGroup.controls.Detail.controls.StartDate.value
      );
      const endDate = this.formatDate(
        this.formGroup.controls.Detail.controls.EndDate.value
      );
      offer.Detail.StartDate = startDate;
      offer.Detail.EndDate = endDate;

      offer = this.clearNulls(offer);

      offer.OfferTagIds = this.updatedOfferTagIds;
      if (this.formGroup.controls.Detail.controls.ProductCategoryIds.value) {
        offer.ProductCategoryIds = this.formGroup.controls.Detail.controls.ProductCategoryIds.value?.map((cat) => cat.Id)
      } else offer.ProductCategoryIds = [];
      offer.VersionIds = this.offerService.selectedVersionsIds;
      offer.BadgeAssetId = this.formGroup.controls.Detail.controls.BadgeAssetId
        .value as string;
      delete offer.Detail.BadgeAssetId;
      offer.OfferTypeId = this.formGroup.controls.Detail.controls.OfferTypeId
        .value as string;
      delete offer.Detail.OfferTypeId;
      offer.PageNumber = this.formGroup.controls.Detail.controls.PageNumber
        .value as string;
      offer.Position = this.formGroup.controls.Detail.controls.Position
        .value as number;
      offer.PrintPageId = this.formGroup.controls.Detail.controls.PrintPageId
        .value as string;
      delete offer.Detail.PageNumber;
      delete offer.Detail.Position;
      delete offer.Detail.PrintPageId;

      const objecttoPass =
        this.offerId !== '0' ? this.getUpdatedRecordInfo(offer) : offer;
      const offers =
        this.offerService.selection.selected &&
          this.offerService.selection.selected.length > 0
          ? this.offerService.selection.selected.map((x) => x.Id)
          : [this.offerId];

      let apiToCall;
      if (
        objecttoPass.Detail &&
        (!objecttoPass.Detail.Id || objecttoPass.Detail.Id === '0')
      ) {
        this.getUpdatedPrintDetails(objecttoPass);
        apiToCall = this.offerService.saveOffer(objecttoPass, this.eventId);
      } else {
        delete objecttoPass.Properties?.PageNumber;
        delete objecttoPass.Properties?.Position;
        delete objecttoPass.Properties?.PrintPageId;
        if (objecttoPass.Properties &&
          Object.keys(objecttoPass.Properties).length === 0 &&
          objecttoPass.Properties.constructor === Object) delete objecttoPass.Properties;
        this.getUpdatedPrintDetails(objecttoPass);
        if (!this.offerService.getNewVersion && this.jaiCreativeAdded && this.currentJAICreativeDetail) {
          if (this.prevHeadline != this.formGroup.controls.Detail.controls.Headline.value || this.prevOfferBodyCopy != this.formGroup.controls.Detail.controls.BodyCopy.value) {
            objecttoPass.Properties["JAIDetails"] = `${this.currentJAICreativeDetail.Id}||${this.formGroup.controls.Detail.controls.Headline.value}||${this.formGroup.controls.Detail.controls.BodyCopy.value}`;
          } else {
            objecttoPass.Properties["JAIDetails"] = `${this.currentJAICreativeDetail.Id}`;
          }
        }

        apiToCall = this.offerService.getNewVersion
          ? this.offerService.cloneOffer(offer.Detail.Id, offer)
          : this.offerService.saveOfferProperties(offers, objecttoPass);
      }

      apiToCall.subscribe({
        next: (response: any) => {
          this.offerService.newVersion = '';
          if (response) {
            const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
              data: {
                title: 'Saved Successfully',
                //message: 'Saved Successfully',
                confirmText: 'Continue Editing',
                cancelText: 'Back to Offers',
              },
              disableClose: true,
            });
            confirmDialog.afterClosed().subscribe((confirmResult) => {
              if (confirmResult) {
                this.routeQueryParams = this.route.snapshot.queryParams;
                this.offerId = response.Id;
                this.offerService.reload();
                this.router
                  .navigate([`../../${response.Id}/details`], {
                    relativeTo: this.route,
                  })
                  .then((x) => {
                    this.reloadCurrentRoute();
                  });
              } else {
                this.offerpromoService.reload();
                this.router.navigate([`../../`], {
                  relativeTo: this.route,
                  queryParamsHandling: 'preserve'
                });
              }
            });
          }
        },
        error: (error) => {
          if (error.status === 500) {
            log.error('500 Error saving offer', error);
            this.matSnackBar.open('500 Error saving offer', 'Error', {
              verticalPosition: 'top',
              panelClass: ['snackbar-error'],
            });
          }
          if (error.status === 400) {
            const apiValidations: any = error.error;
            if (Array.isArray(apiValidations)) {
              apiValidations.forEach((validation: any) => {

                if (
                  validation.PropertyName != null &&
                  validation.PropertyName != undefined
                ) {
                  validation.PropertyName = validation.PropertyName.replace(
                    'OfferDomain.Detail.',
                    ''
                  );
                }
                if (
                  this.formGroup?.controls.Detail.get(validation.PropertyName)
                ) {
                  const control = this.formGroup?.controls.Detail.get(
                    validation.PropertyName
                  );
                  if (control) {
                    control.markAsTouched();
                    control.setErrors({ invalid: validation.ErrorMessage });
                    this.matSnackBar.open(validation.ErrorMessage, 'Error', {
                      verticalPosition: 'top',
                      panelClass: ['snackbar-error'],
                    });
                  }
                } else {
                  this.matSnackBar.open(error.error, 'Error', {
                    verticalPosition: 'top',
                    panelClass: ['snackbar-error'],
                  });

                  ///TODO: if we have cross field validation then show the validation error at the top of the screen
                  // if we have cross field validation then show the validation error at the top of the screen
                  // push general error messages to array this is displayed in a toast or dialog
                }
              });
            } else {
              this.matSnackBar.open(apiValidations, 'Error', {
                verticalPosition: 'top',
                panelClass: ['snackbar-error'],
              });
            }
          }
        },
      });
    }
  }
  openMontageEditor() {
    // Need to load the Motage Editor
    this.montageBuilderService.montageBuilderStatusBehaviorSubject.next(true);

    this.router.navigate([`../`, `montage-editor`], { relativeTo: this.route });

  }

  getUpdatedPrintDetails(objecttoPass: any) {
    if (objecttoPass.PageNumber || objecttoPass.PageNumber == "" || objecttoPass.Position || objecttoPass.Position == null) {
      objecttoPass['PrintPageId'] = this.formGroup.controls.Detail.controls.PrintPageId
        .value as string;
      objecttoPass['PageNumber'] = this.formGroup.controls.Detail.controls.PageNumber
        .value as string;
      this.formGroup.controls.Detail.controls.Position
        .value as number == null ? objecttoPass['Position'] = -1 : objecttoPass['Position'] = this.formGroup.controls.Detail.controls.Position
          .value as number;
    }
  }
  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl], {
        queryParams: this.routeQueryParams,
        queryParamsHandling: 'merge',
      });
    });
  }

  cancel(): void {
    if (this.formGroup.dirty && this.canEdit() == true) {
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: 'Close without saving?',
          message: 'This offer has unsaved changes',
          confirmText: 'Continue Editing',
          cancelText: 'Close',
        },
        disableClose: true,
      });
      confirmDialog.afterClosed().subscribe((confirmResult) => {
        if (!confirmResult) {
          this.formGroup.reset();
          this.router.navigate([`../../`], {
            relativeTo: this.route,
            queryParamsHandling: 'preserve'
          });
        }
      });
    } else {
      this.formGroup.reset();
      this.router.navigate([`../../`], {
        relativeTo: this.route,
        queryParamsHandling: 'preserve'
      });
    }
  }

  updateTableStatus(status: Status) {
    let selectedRecordsDetails: any;
    const responseOffers =
      this.offerService.selection.selected &&
        this.offerService.selection.selected.length > 0
        ? this.offerService.selection.selected
        : [this.offerResponse];
    selectedRecordsDetails = responseOffers;
    this.updateRecordsStatus(selectedRecordsDetails, status);
  }

  getUpdatedObject(selectedRecords: any) {
    let datatoReturn = [];
    if (selectedRecords && selectedRecords.length > 0) {
      for (let index = 0; index <= selectedRecords.length - 1; index++) {
        if (!selectedRecords[index].Detail) {
          datatoReturn.push({
            Detail: selectedRecords[index],
            EventType: 'OFFER',
          });
        } else {
          datatoReturn.push(selectedRecords[index]);
        }
      }
    }
    return datatoReturn;
  }

  updateRecordsStatus(selectedRecordsDetails: Array<any>, status: any) {
    if (selectedRecordsDetails && selectedRecordsDetails.length > 0) {
      const recordstoupdate = selectedRecordsDetails.map((x: any) => {
        return {
          Id: x.Id,
          ClientKey: x.ClientKey,
          UserId: x.UserId,
          Name: x.Name,
          Headline: x.Headline,
          BodyCopy: x.BodyCopy,
          Disclaimer: x.Disclaimer,
          ClientComment: x.ClientComment,
          StartDate: dayjs(x.StartDate).format('MM/DD/YYYY'),
          EndDate: dayjs(x.EndDate).format('MM/DD/YYYY'),
          Rank: x.Rank,
          TargetURL: x.TargetURL,
          Status: status,
          Version: x.Version,
          Label: 'OFFER',
        };
      });

      this.offerpromoService.updateStatus(recordstoupdate).subscribe({
        next: () => {
          this.getDataById();
        },
      });
    }
  }

  deleteRecord(versionName?: string) {
    if (versionName == 'Base' || this.canEdit() == false) return;
    const record = this.formGroup.getRawValue();
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Delete',
        message: `Are you sure you wish to delete ${record.Detail.Name}?`,
      },
      disableClose: true,
    });

    confirmDialog.afterClosed().subscribe((confirmResult) => {
      if (confirmResult) {
        this.offerService
          .deleteOffer(record.Detail.Id, this.eventId)
          .subscribe({
            next: () => {
              this.matSnackBar.open(
                `${record.Detail.Name} deleted`,
                'OK',
                DEFAULT_SNACKBAR_CONFIG
              );
              this.offerpromoService.reload();
              this.router.navigate([`../../`], {
                relativeTo: this.route,
                queryParamsHandling: 'preserve'
              });
            },
            error: (error) => {
              log.error('Error deleting offer', error);

              if (error.error.value) {
                throw new Error(error.error.value);
              } else {
                throw new Error(error.message);
              }
            },
          });
      }
    });
  }

  deleteBaseRecord() {
    if (this.canEdit() == false) {
      return;
    }
    const record = this.formGroup.getRawValue();
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Delete',
        message: `Are you sure you wish to delete ${record.Detail.Name}?`,
      },
      disableClose: true,
    });

    confirmDialog.afterClosed().subscribe((confirmResult) => {
      if (confirmResult) {
        this.offerService
          .deleteOfferVersions(record.Detail.Id, this.eventId)
          .subscribe({
            next: () => {
              this.matSnackBar.open(
                `${record.Detail.Name} deleted`,
                'OK',
                DEFAULT_SNACKBAR_CONFIG
              );
              this.offerpromoService.reload();
              this.router.navigate([`../../`], {
                relativeTo: this.route,
                queryParamsHandling: 'preserve'
              });
            },
            error: (error) => {
              log.error('Error deleting offer', error);

              if (error.error.value) {
                throw new Error(error.error.value);
              } else {
                throw new Error(error.message);
              }
            },
          });
      }
    });
  }

  tagSelected(tagId: string): void {
    if (this.updatedOfferTagIds?.length > 0 && this.updatedOfferTagIds.includes(tagId)) {
      return;
    }
    this.updatedOfferTagIds.push(tagId);
  }

  addTag(offerTag: OfferTag): void {
    this.updatedOfferTagIds.push(offerTag.Id);
    this.matSnackBar.open(`${offerTag.OfferTagName} saved`, 'OK', DEFAULT_SNACKBAR_CONFIG);
  }

  removeTag(tagId: string): void {
    this.updatedOfferTagIds = this.updatedOfferTagIds.filter(id => id !== tagId);
  }

  canEdit(): boolean {
    const hasAccess =
      this.authorizationService.checkAccess('edit||offermang') &&
      this.readOnlyMode != true;
    if (hasAccess) {
      this.formGroup.disabled ? this.formGroup.enable() : null;
      this.tagCtrl.enable();
    } else {
      this.formGroup.enabled ? this.formGroup.disable() : null;
      this.tagCtrl.disable();
    }

    return hasAccess;
  }

  getUpdatedRecordInfo(record: any) {
    const objecttoPass: any = {
      Properties: {},
    };
    const currentOfferInfo = this.offerService.getCurrentRecord;
    if (currentOfferInfo) {
      const properties = Object.keys(record);
      for (let index = 0; index <= properties.length - 1; index++) {
        if (
          record[properties[index]] != null &&
          record[properties[index]] != undefined &&
          typeof record[properties[index]] === 'object' &&
          !Array.isArray(record[properties[index]])
        ) {
          const childProperties = Object.keys(record[properties[index]]);
          if (childProperties && childProperties.length > 0) {
            const currentRecord = currentOfferInfo[properties[index]];
            const updatedRecord = record[properties[index]];
            for (
              let childIndex = 0;
              childIndex <= childProperties.length - 1;
              childIndex++
            ) {
              if (
                currentRecord[childProperties[childIndex]] !==
                updatedRecord[childProperties[childIndex]] &&
                updatedRecord[childProperties[childIndex]] != null &&
                updatedRecord[childProperties[childIndex]] != undefined
              ) {
                objecttoPass['Properties'][childProperties[childIndex]] =
                  updatedRecord[childProperties[childIndex]];
              }
            }
          }
        } else if (
          (!Array.isArray(currentOfferInfo[properties[index]]) &&
            currentOfferInfo[properties[index]] !==
            record[properties[index]]) ||
          (Array.isArray(currentOfferInfo[properties[index]]) &&
            !this.areArraysEqual(
              currentOfferInfo[properties[index]],
              record[properties[index]]
            ))
        ) {
          objecttoPass[properties[index]] = record[properties[index]];
        }
      }
    }
    return objecttoPass;
  }
  areArraysEqual(arr1: string[], arr2: string[]): boolean {
    if (arr1.length !== arr2.length) {
      return false;
    }
    const sortedArr1 = arr1.slice().sort();
    const sortedArr2 = arr2.slice().sort();
    for (let i = 0; i < sortedArr1.length; i++) {
      if (sortedArr1[i] !== sortedArr2[i]) {
        return false;
      }
    }
    return true;
  }
  openDialog($event: any, offer: any) {
    if (this.isCommentDialogOpened == false) {
      let commentElementStyle = document.getElementById(offer.DomainId) as HTMLElement;
      const dialogConfig = new MatDialogConfig();
      dialogConfig.hasBackdrop = true;
      dialogConfig.disableClose = true;
      dialogConfig.data = {
        headline: offer.Detail.Headline,
        channel: 'OFFER',
        parentId: offer.Detail.Id,
        clientKey: offer.Detail.ClientKey,
        bodyCopy: offer.Detail.BodyCopy,
      };
      let confirmDialog = this.dialog.open(CommentsComponent, dialogConfig);
      confirmDialog.afterOpened().subscribe(() => {
        if (commentElementStyle) {
          commentElementStyle.style.color = "blue";
          this.offerpromoService.commentDialogBoxStatusBehaviorSubject.next(true);
        }
      });
      confirmDialog.afterClosed().subscribe((data) => {
        if (data > 0) {
          Object(offer).CommentsCount = data;
        }
        if (commentElementStyle) {
          commentElementStyle.style.color = "black";
          this.offerpromoService.commentDialogBoxStatusBehaviorSubject.next(false);
        }
      });
    }
  }

  clearSearch() {
    this.categoryCtrl.patchValue('');
    this.formGroup.controls.Detail.controls.ProductCategoryIds.patchValue(this.selectedItems);
  }

  onCategoryRemoved(category: string) {
    const categories = this.formGroup.controls.Detail.controls.ProductCategoryIds.value as string[];
    this.removeFirst(categories, category);
    this.formGroup.controls.Detail.controls.ProductCategoryIds.setValue(categories); // To trigger change detection
  }

  private removeFirst<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }

  ngOnDestroy() {
    this.dialog.closeAll();
    this.offerServiceSubs.unsubscribe();
  }
}
