import { Component, Inject, OnInit } from '@angular/core';
import { combineLatest, distinctUntilChanged, map, Subscription } from 'rxjs';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { Logger } from 'src/app/@shared';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { Version, VersionService } from '../..';
import { ActivatedRoute } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';

const log = new Logger('OfferGroupVersionsComponent');

interface DialogData {
  event: string;
  versions: Version[];
}

@Component({
  selector: 'app-offer-group-versions',
  templateUrl: './offer-group-versions.component.html',
  styleUrls: ['./offer-group-versions.component.scss']
})

export class OfferGroupVersionsComponent implements OnInit {
  search = new FormControl<string>('');
  viewModel$ = combineLatest([
    this.versionService.offerGroupVersions$,
    this.versionService.isLoading$,
    this.versionService.offerGroupVersionstotalRecords$,
    this.versionService.offerGroupVersionsPage$,
  ]).pipe(
    map(([versions, isLoading, totalRecords, page]) => {
      return { versions, isLoading, totalRecords, page }
    }),
  );
  flexMediaWatcher!: Subscription;
  versionList: Version[] = [];
  selection = new SelectionModel<Version>(true, []);
  displayedColumns = ['select', 'VersionName'];
  constructor(private versionService: VersionService<Version>, private mediaObserver: MediaObserver, private route: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogData,
    public dialogRef: MatDialogRef<OfferGroupVersionsComponent>) { }

  ngOnInit(): void {
    log.debug('init');
    this.versionService.eventId = this.dialogData?.event;
    if (this.dialogData.versions) {
      this.selection = new SelectionModel<Version>(true, this.dialogData.versions);
    }
    this.versionService.offerGroupVersions$.subscribe((data) => {
      this.versionList = data;
    })
    // detect changes in viewport size to handle show/hide of table columns
    const getAlias = (MediaChange: MediaChange[]) => {
      return MediaChange[0].mqAlias;
    };

    this.flexMediaWatcher = this.mediaObserver
      .asObservable()
      .pipe(
        distinctUntilChanged(
          (x: MediaChange[], y: MediaChange[]) => getAlias(x) === getAlias(y)
        ))
      .subscribe((change) => {
        if (change.some(x => x.mqAlias === 'xs')) {
          this.displayedColumns = ['VersionName'];
        }
        else if (change.some(x => x.mqAlias === 'sm')) {
          this.displayedColumns = ['select', 'VersionName'];
        }
        else {
          this.displayedColumns = ['select', 'VersionName'];
        }
      });
  }

  ngOnDestroy(): void {
    this.flexMediaWatcher.unsubscribe();
  }

  onSearch(event: any) {
    this.versionService.offerGroupVersionsSearch(event.target.value);
  }

  clearSearch() {
    this.search.setValue('');
    this.versionService.offerGroupVersionsSearch('');
  }

  onSort(sortState: Sort): void {
    this.versionService.offerGroupVersionsSort(sortState);
  }

  onPage(pageEvent: PageEvent): void {
    this.versionService.offerGroupVersionsPage(pageEvent);
  }

  closeDialog() {
    this.dialogRef.close();
    this.versionService.offerGroupVersionsSearch('');
  }

  saveData() {
    this.dialogRef.close(this.selection.selected);
    this.versionService.offerGroupVersionsSearch('');
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.versionList.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.versionList.forEach(row => this.selection.select(row));
  }

  public getSelectedSectionRecords() {
    return this.selection.selected;
  }
}
