import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { map, combineLatest } from 'rxjs';
import { ConfirmDialogComponent, Logger } from 'src/app/@shared';
import { DEFAULT_SNACKBAR_CONFIG } from 'src/app/@shared/constants/site.constants';
import { Offer, OfferDomain, OfferService, Product, OfferProductService, OfferAddProductsComponent, PromoDomain, OfferPromo, OfferPromoService, MontageBuilderService } from '../..';
import { AuthorizeControlService } from 'pr1-ui-components';

const log = new Logger('OfferProductsComponent');

@Component({
  selector: 'app-offer-products',
  templateUrl: './offer-products.component.html',
  styleUrls: ['./offer-products.component.scss'],
})

export class OfferProductsComponent<T extends Product> implements OnInit, OnDestroy {
  viewModel$ = combineLatest([
    this.offerProductService.isLoading$,
    this.offerProductService.viewMode$,
    this.offerProductService.productOfferId$,
    this.offerPromoService.getoffersPromos$,
    this.montageBuilderService.montageBuilderCheckConfigure$,
    this.montageBuilderService.montageGetEventType$
  ]).pipe(
    map(([isLoading, viewMode, productOfferId, offers,montageBuilderCheckConfigureStatus,montageGetEventType]) => {
      return { isLoading, viewMode, productOfferId, offers,montageBuilderCheckConfigureStatus,montageGetEventType }
    }),
  );

  filtersForm = new FormGroup({
    search: new FormControl(),
  });
  @ViewChild('productsTable', { static: false }) productsTable: any;
  @ViewChild('productsCards', { static: false }) productsCards : any;
  public productsList: any;

  constructor(private offerProductService: OfferProductService<T>,
    private offerService: OfferService<Offer, OfferDomain<Offer>>,
    private offerPromoService: OfferPromoService<OfferPromo, PromoDomain<OfferPromo>>,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private matSnackBar: MatSnackBar,    
    private authorizationService: AuthorizeControlService,
    private montageBuilderService:MontageBuilderService,
  ) { }

  ngOnInit(): void {
    log.debug('init');
    this.clearSearch();
    this.offerService.eventId = this.route.snapshot.params['eventId'];
    this.offerProductService.offerId = this.route.snapshot.params['offerId'];
    this.offerProductService.products$.subscribe((data) => {
      this.productsList = data;
    });
  }

  ngOnDestroy() { }

  onSearch(event: any) {
    this.offerProductService.search(event.target.value);
  }
  openMontageEditor(){
// Need to load the Motage Editor
    this.montageBuilderService.montageBuilderStatusBehaviorSubject.next(true);
    
    this.router.navigate([`../`, `montage-editor`], { relativeTo: this.route });

  }
  clearSearch() {
    this.filtersForm.controls.search.setValue('');
    this.offerProductService.search('');
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(OfferAddProductsComponent, {
      width: '80%',
      height: '90%',
      data: { offerId: this.route.snapshot.params['offerId'] }
    });
    // this.offerProductService.dialogSearch('');
  }


  toggleView(mode: string) {
    this.offerProductService.toggleViewMode(mode);
  }

  deleteSelectedRecords() {
    let selectedRecords: any;
    if (this.productsTable) {
      selectedRecords = this.productsTable.getSelectedSectionRecords();
    } else if (this.productsCards ){
      selectedRecords = this.productsCards.getSelectedSectionRecords();
    }
    if (selectedRecords && selectedRecords.length > 0) {
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: 'Confirm Delete',
          message: `Do you want to remove Product?`,
        },
        disableClose: true,
      });


      confirmDialog.afterClosed().subscribe(
        confirmResult => {
          if (confirmResult) {
            const offers = (this.offerService.selection.selected && this.offerService.selection.selected.length > 0) ? this.offerService.selection.selected.map(x => x.Id) : [this.offerProductService.offerId];
            this.offerProductService.disassociateProductToOffer(offers, selectedRecords).subscribe({
              next: () => {
                this.matSnackBar.open(`Product(s) deleted`, 'OK', DEFAULT_SNACKBAR_CONFIG);
                if (this.productsTable) {
                  this.productsTable.clearSelection();
                } else if (this.productsCards) {
                  this.productsCards.clearSelection();
                }
                this.offerProductService.reload();
                this.router.navigate([`../`, 'products'], { relativeTo: this.route, queryParamsHandling: 'preserve' });
              },
              error: (error) => {
                log.error('Error adding store to version', error);

                if (error.error.value) {
                  throw new Error(error.error.value);
                } else {
                  throw new Error(error.message);
                }
              }
            });
          }
        });
    }

  }
  cancel(): void {
    this.router.navigate([`../../`], { relativeTo: this.route, queryParamsHandling: 'preserve' });
  }

  getOfferName(offers: any) {
    if (offers && offers.length > 0) {
      const offerDetail = offers.find((x: PromoDomain<OfferPromo>) => x.DomainId === this.route.snapshot.params['offerId']);
      if (offerDetail && offerDetail.Detail) {
        return offerDetail.Detail.Name;
      } else {
        return '';
      }
    }
    return '';
  }

  canEdit(): boolean {
    const hasAccess = this.authorizationService.checkAccess('edit||offermang');
    return hasAccess;
  }
}
