import { MatSnackBarConfig } from '@angular/material/snack-bar';
import { Organization } from '..';

export const DEFAULT_PAGING = {
    previousPageIndex: 0,
    pageIndex: 0,
    pageSize: 50,
    length: 0,
};

export const DEFAULT_SNACKBAR_CONFIG: MatSnackBarConfig = {
  horizontalPosition: 'center',
  verticalPosition: 'top',
  duration: 3000,
};

export const ORGANIZATIONS: Organization[] = [
  {
    id: '9b066a1f-635a-43f5-a46e-e55906236388',
    name: 'RiteAid',
    apiPath: 'RiteAid',
    version: 'v1',
    dwa_api_key: 'pgH7QzFHJx4w46fI~5Uzi4RvtTwlEXp3',
    enableJunctionAI: false
  }
];

export const UserRolesInWorkflow: any = {
  reviewer: 0,
  editor: 1,
  approver: 2,
};
