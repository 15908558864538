import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { Observable, Subscription, combineLatest, forkJoin, map, of, tap } from 'rxjs';
import { ConfirmDialogComponent, DEFAULT_SNACKBAR_CONFIG, OrganizationService } from 'src/app/@shared';
import { Division, DivisionService, EditIntegrationDialogComponent } from '../../..';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { SettingsService } from '../../../services/settings.service';

@Component({
  selector: 'app-admin-integrations',
  templateUrl: './integrations.component.html',
  styleUrls: ['./integrations.component.scss'],
})
export class AdminIntegrationComponent<TDivision extends Division> implements OnInit {
  public integrations: any = [];

  constructor(
    private settingsService: SettingsService,
    private dialog: MatDialog, private matSnackBar: MatSnackBar) { }

  ngOnInit() {
    this.getIntegrations();
  }

  getIntegrations() {
    this.settingsService.getIntegrations().subscribe((res: any) => {
      this.integrations = res.value;
      if (this.integrations && this.integrations.length > 0) {
        const aiAutomation = this.integrations.find((i: any) => i.ClientKey === 'aiautomation');
        if (!aiAutomation) {
          this.creaetAIAutomation();
        }
      } else {
        this.creaetAIAutomation();
      }
    });
  }

  creaetAIAutomation() {
    if (!this.integrations)
      this.integrations = [];

    const aiIntegration = {
      AIClientKey: "",
      ClientId: "",
      ClientKey: "aiautomation",
      Endpoint: "",
      IntegrationName: "AI Automation",
      Label: "Integration",
      OrganizationName: "",
      PartitionKey: "Integration",
      Status: false,
      Subscriptions: "",
      TemplateId: ""
    }
    this.integrations.push(aiIntegration);
  }

  openDialog(integration: any): void {
    const dialogRef = this.dialog.open(EditIntegrationDialogComponent, {
      width: '50%',
      data: { id: integration.Id }
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.getIntegrations();
      }
    });
  }

  updateStatus(event: any, currentData: any) {
    if (!currentData) return;
    const intData = {
      Id: currentData.Id,
      ClientKey: currentData.ClientKey,
      IntegrationName: currentData.IntegrationName,
      Status: event.checked,
      ClientId: currentData.ClientId,
      AIClientKey: currentData.AIClientKey,
      Subscriptions: currentData.Subscriptions,
      OrganizationName: currentData.OrganizationName,
      Endpoint: currentData.Endpoint,
      TemplateId: currentData.TemplateId,
    }
    this.settingsService.updateIntegration(intData, currentData.Id).subscribe({
      next: (response) => {
        this.getIntegrations();
      },
      error: (error) => {
        if (error.status === 400) {
          const apiValidations: any = error.error;
          this.matSnackBar.open(apiValidations, 'Error', { verticalPosition: 'top', panelClass: ['snackbar-error'] });
        }
      }
    });
  }
}