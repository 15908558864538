import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, tap, map, Subject, debounceTime } from 'rxjs';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
  Logger,
  ApiValidation,
  ConfirmDialogComponent,
  PreviewMode,
  UtilityService,
} from 'src/app/@shared';
import {
  AssetGroup,
  AssetTag,
  AssetTagService,
  EventService,
  Offer,
  OfferDomain,
  OfferService,
  OfferTag,
  OfferTagService,
  Event,
} from '../..';
import { DEFAULT_SNACKBAR_CONFIG } from 'src/app/@shared/constants/site.constants';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { OfferPromo, PromoDomain, OfferPromoService } from '../..';
import { Status } from '../../models/offer-promo-status';
import * as dayjs from 'dayjs';
import { AuthorizeControlService, CommentsComponent } from 'pr1-ui-components';
import { EventWorkflowService } from '../../services/event-workflow.service';

const log = new Logger('PromoDetailsComponent');

@Component({
  selector: 'app-promo-details',
  templateUrl: './promo-details.component.html',
  styleUrls: ['./promo-details.component.scss'],
})
export class PromoDetailsComponent<
  T extends Offer,
  Tag extends OfferTag,
  TOfferDomain extends OfferDomain<Offer>,
  TOfferPromo extends OfferPromo,
  TEvent extends Event,
  TPromoDomain extends PromoDomain<OfferPromo>
> implements OnInit, AfterViewInit, AfterContentChecked, OnDestroy {
  offer$: Observable<PromoDomain<OfferPromo>> = of(
    {} as PromoDomain<OfferPromo>
  );
  assetsforBadge: any;
  assetGroups = [];
  noneValue = null;

  tagCtrl = new FormControl<string>('', [
    Validators.minLength(1),
    Validators.maxLength(50),
  ]);
  offerTagIds: string[] = [];
  updatedOfferTagIds: string[] = [];
  formGroup = new FormGroup({
    // ParentOfferId: new FormControl<string | null>(''),
    Detail: new FormGroup({
      ClientKey: new FormControl<string>('', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(200),
      ]),
      Id: new FormControl<string>(
        { value: '', disabled: true },
        { nonNullable: true }
      ),
      StartDate: new FormControl<Date | string | null>(null, [
        Validators.required,
      ]), // Validators.pattern("[0-9 \\-/]*"
      EndDate: new FormControl<Date | string | null>(null, [
        Validators.required,
      ]), // Validators.pattern("[0-9 \\-/]*"
      Rank: new FormControl<number>(1, [
        Validators.min(1),
        Validators.max(9999999),
      ]),
      Name: new FormControl<string>('', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(100),
      ]),
      Headline: new FormControl<string>(''),
      BodyCopy: new FormControl<string>('', [
        Validators.minLength(1),
        Validators.maxLength(100),
      ]),
      Disclaimer: new FormControl<string>('', [
        Validators.minLength(1),
        Validators.maxLength(200),
      ]),
      ClientComment: new FormControl<string>('', [
        Validators.minLength(1),
        Validators.maxLength(200),
      ]),
      TargetURL: new FormControl<string>('', [
        Validators.minLength(1),
        Validators.maxLength(5000),
      ]),
      AlternateText: new FormControl<string>(''),
      AdditionalText: this.fb.array([]),
      BadgeAssetId: new FormControl<string | null>(null),
      Status: new FormControl<string>('PENDING'),
      OfferVariantName: new FormControl<string | null>(null),
    }),
  });
  offerId: string = '0';
  eventId!: string;
  PreviewMode = PreviewMode;
  maxDate: Date = new Date(9999, 0, 1);
  //buttonClicked = new Subject();
  private offerTagsList: Tag[] = [];
  private promoResponse: any;
  offerpromosList: TPromoDomain[] = [];
  versionsCount: number = 0;
  readOnlyMode: any = null;
  routeQueryParams: any = {};
  isCommentDialogOpened: boolean = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private offerService: OfferService<T, TOfferDomain>,
    private offerTagService: OfferTagService<Tag>,
    private offerpromoService: OfferPromoService<TOfferPromo, TPromoDomain>,
    private matSnackBar: MatSnackBar,
    public utilityService: UtilityService,
    private assetTagService: AssetTagService<AssetTag, AssetGroup>,
    private eventService: EventService<TEvent>,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private authorizationService: AuthorizeControlService,
    private cdRef: ChangeDetectorRef,
    private ewService: EventWorkflowService
  ) { }

  ngOnInit(): void {
    log.debug('init');
    this.offerpromoService.commentDialogBoxStatus$.subscribe((status: boolean) => { this.isCommentDialogOpened = status });
    this.offerpromoService.eventId = this.route.snapshot.params['eventId'];
    // get the offer id from the parent route
    this.route.params.subscribe((params) => {
      this.offerId = params['offerId'];
      this.eventId = params['eventId'];
      this.initLoad();
    });

    this.offerService.newVersion$.subscribe((res) => {
      if (res) {
        this.getData();
      }
    });

    this.offerService.versionsCount$.subscribe((res) => {
      this.versionsCount = res;
    });
    // const buttonClickedDebounced = this.buttonClicked.pipe(debounceTime(1000));
    // buttonClickedDebounced.subscribe(() => {
    //   this.saveOffer();
    // });

    this.readOnlyMode = this.offerpromoService.getOfferDetailsReadOnlyValue();
    this.offerpromoService.offerDetailsReadOnlyMode$.subscribe((res) => {
      this.readOnlyMode = res;
    });
  }

  ngAfterViewInit() {
    const eventId = this.route.snapshot.params['eventId'];
  }

  ngAfterContentChecked() {
    this.cdRef.detectChanges();
  }

  initLoad() {

    this.getData();
    this.offerService.getAssets().subscribe((res: any) => {
      this.assetsforBadge = res.value;
    });

    this.offerService.saveorCancel$.subscribe((res) => {
      if (res === '1') {
        this.save();
      } else if (res === '0') {
        this.cancel();
      }
    });
  }

  navigateChildOffers() {
    this.router.navigate([`../../`, `promo`, `childversions`], {
      relativeTo: this.route, queryParamsHandling: 'preserve'
    });
  }

  getData() {
    if (this.offerId && this.offerId !== '0') {
      this.offerTagIds = [];
      this.updatedOfferTagIds = [];
      this.formGroup.controls['Detail'].controls['AdditionalText'] =
        this.fb.array([]);
      this.offer$ = this.offerpromoService.getPromo(this.offerId).pipe(
        tap((offer) => {
          offer.EventType = 'PROMO';
          this.offerService.currentRecord = JSON.parse(JSON.stringify(offer));
          if (offer && offer.Detail && offer.Detail.StartDate) {
            offer.Detail.StartDate = new Date(offer.Detail.StartDate);
          }
          if (offer && offer.Detail && offer.Detail.EndDate) {
            offer.Detail.EndDate = new Date(offer.Detail.EndDate);
          }
          const newVersionName = this.offerService.getNewVersion;
          if (newVersionName) {
            offer.Detail.ClientKey = '';
            //this.offerId = '0';
            offer.Detail.OfferVariantName = newVersionName;
          }
          this.formGroup.patchValue(offer as any);
          this.promoResponse = offer;
          if (
            offer &&
            offer.Detail &&
            offer.Detail.AdditionalText &&
            offer.Detail.AdditionalText.length > 0
          ) {
            for (
              let index = 0;
              index <= offer.Detail.AdditionalText.length - 1;
              index++
            ) {
              this.addAdditionalText(offer.Detail.AdditionalText[index]);
            }
          } else {
            this.addAdditionalText('');
          }

          this.formGroup.controls.Detail.controls.BadgeAssetId.patchValue(
            offer?.BadgeAssetId as string
          );
          if (offer.OfferTagIds && offer.OfferTagIds.length) {
            this.offerTagIds = offer.OfferTagIds;
            this.updatedOfferTagIds = offer.OfferTagIds;
          }
          this.formGroup.markAllAsTouched();
        })
      );
    } else {
      this.eventService.getEventDomain(this.eventId).subscribe((res) => {
        // const eventStartDate = dayjs(res?.Detail.StartDate).format('YYYY-MM-DD');
        // const eventEndDate = dayjs(res?.Detail.EndDate).format('YYYY-MM-DD');
        this.formGroup.controls.Detail.controls.StartDate.patchValue(
          new Date(res?.Detail.StartDate as any)
        );
        this.formGroup.controls.Detail.controls.EndDate.patchValue(
          new Date(res?.Detail.EndDate as any)
        );
      });
      this.addAdditionalText('');
    }
  }

  validateDate(): boolean {
    let startDate = this.formGroup.controls.Detail.controls.StartDate;
    let endDate = this.formGroup.controls.Detail.controls.EndDate;
    if (endDate && endDate.value && startDate && startDate.value) {
      if (endDate.value <= startDate.value) {
        this.matSnackBar.open(
          'End Date must be greater than Start Date',
          'Error',
          { verticalPosition: 'top', panelClass: ['snackbar-error'] }
        );
        return false;
      }
    }
    return true;
  }

  save() {
    //this.buttonClicked.next('');
    this.saveOffer();
  }

  trimControlValues(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach((key: string) => {
      const abstractControl = formGroup.get(key);
      if (abstractControl instanceof FormGroup) {
        this.trimControlValues(abstractControl);
      } else {
        if (typeof abstractControl?.value == 'string') {
          abstractControl.setValue(abstractControl?.value.trim());
        }
      }
    });
  }

  formatDate(date: Date | string | null) {
    if (date) {
      const newDate = dayjs(date).format('MM/DD/YYYY');
      return newDate;
    } else {
      return '';
    }
  }

  saveOffer() {
    this.trimControlValues(this.formGroup);
    this.formGroup.markAsDirty();
    this.formGroup.markAllAsTouched();
    if (this.formGroup.valid) {
      if (!this.validateDate()) return;
      let promo = this.formGroup.getRawValue() as PromoDomain<TOfferPromo>;
      const startDate = this.formatDate(
        this.formGroup.controls.Detail.controls.StartDate.value
      );
      const endDate = this.formatDate(
        this.formGroup.controls.Detail.controls.EndDate.value
      );
      promo.Detail.StartDate = startDate;
      promo.Detail.EndDate = endDate;
      promo.Detail.Rank = promo.Detail.Rank || 1;
      promo.OfferTagIds = this.updatedOfferTagIds;
      promo.VersionIds = this.offerService.selectedVersionsIds;
      promo.BadgeAssetId = this.formGroup.controls.Detail.controls.BadgeAssetId
        .value as string;
      promo.EventId = this.eventId;
      delete promo.Detail.BadgeAssetId;
      const additionaltexts =
        promo.Detail.AdditionalText && promo.Detail.AdditionalText.length > 0
          ? promo.Detail.AdditionalText.filter(
            (i: any) => i.addtionalTextDetail
          ).map((x: any) => x.addtionalTextDetail)
          : [];
      promo.Detail.AdditionalText = additionaltexts;
      // const apiToCall = this.offerService.getNewVersion ? this.offerpromoService.clonePromo(promo.Detail.Id, promo.Detail) : this.offerpromoService.savePromo(promo);

      const objecttoPass =
        this.offerId !== '0' ? this.getUpdatedRecordInfo(promo) : promo;
      const offers =
        this.offerService.selection.selected &&
          this.offerService.selection.selected.length > 0
          ? this.offerService.selection.selected.map((x) => x.Id)
          : [this.offerId];

      let apiToCall;
      if (
        objecttoPass.Detail &&
        (!objecttoPass.Detail.Id || objecttoPass.Detail.Id === '0')
      ) {
        apiToCall = this.offerpromoService.savePromo(objecttoPass);
      } else {
        apiToCall = this.offerService.getNewVersion
          ? this.offerpromoService.clonePromo(promo.Detail.Id, promo)
          : this.offerpromoService.savePromoProperties(offers, objecttoPass);
      }
      apiToCall.subscribe({
        next: (response: any) => {
          if (response) {
            const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
              data: {
                title: 'Saved Successfully',
                //message: 'Saved Successfully',
                confirmText: 'Continue Editing',
                cancelText: 'Back to Offers',
              },
              disableClose: true,
            });
            confirmDialog.afterClosed().subscribe((confirmResult) => {
              if (confirmResult) {
                this.routeQueryParams = this.route.snapshot.queryParams;
                this.offerId = response.Id;
                this.offerService.reload();
                this.router
                  .navigate([`../../../${response.Id}/promos/promodetails`], {
                    relativeTo: this.route,
                  })
                  .then((x) => {
                    this.reloadCurrentRoute();
                  });
              } else {
                this.offerpromoService.reload();
                this.router.navigate([`../../../`], { relativeTo: this.route, queryParamsHandling: 'preserve' });
              }
            });
          }
        },
        error: (error) => {
          if (error.status === 500) {
            log.error('500 Error saving promo', error);
            this.matSnackBar.open('500 Error saving promo', 'Error', {
              verticalPosition: 'top',
              panelClass: ['snackbar-error'],
            });
          }
          if (error.status === 400) {
            const apiValidations: any = error.error;
            if (Array.isArray(apiValidations)) {
              apiValidations.forEach((validation: any) => {
                if (this.formGroup?.get(validation.PropertyName)) {
                  const control = this.formGroup?.get(validation.PropertyName);
                  if (control) {
                    control.markAsTouched();
                    control.setErrors({ invalid: validation.ErrorMessage });
                    this.matSnackBar.open(validation.ErrorMessage, 'Error', {
                      verticalPosition: 'top',
                      panelClass: ['snackbar-error'],
                    });
                  }
                } else {
                  this.matSnackBar.open(error.error, 'Error', {
                    verticalPosition: 'top',
                    panelClass: ['snackbar-error'],
                  });
                  ///TODO: if we have cross field validation then show the validation error at the top of the screen
                  // if we have cross field validation then show the validation error at the top of the screen
                  // push general error messages to array this is displayed in a toast or dialog
                }
              });
            } else {
              this.matSnackBar.open(apiValidations, 'Error', {
                verticalPosition: 'top',
                panelClass: ['snackbar-error'],
              });
            }
          }
        },
      });
    }
  }

  getUpdatedRecordInfo(record: any) {
    const objecttoPass: any = {
      Properties: {},
    };
    const currentPromoInfo = this.offerService.getCurrentRecord;
    if (currentPromoInfo) {
      const properties = Object.keys(record);
      for (let index = 0; index <= properties.length - 1; index++) {
        if (
          record[properties[index]] != null &&
          record[properties[index]] != undefined &&
          typeof record[properties[index]] === 'object' &&
          !Array.isArray(record[properties[index]])
        ) {
          const childProperties = Object.keys(record[properties[index]]);
          if (childProperties && childProperties.length > 0) {
            const currentRecord = currentPromoInfo[properties[index]];
            const updatedRecord = record[properties[index]];
            for (
              let childIndex = 0;
              childIndex <= childProperties.length - 1;
              childIndex++
            ) {
              if (
                (!Array.isArray(updatedRecord[childProperties[childIndex]]) &&
                  currentRecord[childProperties[childIndex]] !==
                  updatedRecord[childProperties[childIndex]]) ||
                (Array.isArray(updatedRecord[childProperties[childIndex]]) &&
                  !this.areArraysEqual(
                    currentRecord[childProperties[childIndex]],
                    updatedRecord[childProperties[childIndex]]
                  ))
              ) {
                objecttoPass['Properties'][childProperties[childIndex]] =
                  updatedRecord[childProperties[childIndex]];
              }
            }
          }
        } else if (
          (!Array.isArray(currentPromoInfo[properties[index]]) &&
            currentPromoInfo[properties[index]] !==
            record[properties[index]]) ||
          (Array.isArray(currentPromoInfo[properties[index]]) &&
            !this.areArraysEqual(
              currentPromoInfo[properties[index]],
              record[properties[index]]
            ))
        ) {
          objecttoPass[properties[index]] = record[properties[index]];
        }
      }
    }
    return objecttoPass;
  }

  areArraysEqual(arr1: string[], arr2: string[]): boolean {
    if (arr1.length !== arr2.length) {
      return false;
    }
    const sortedArr1 = arr1.slice().sort();
    const sortedArr2 = arr2.slice().sort();
    for (let i = 0; i < sortedArr1.length; i++) {
      if (sortedArr1[i] !== sortedArr2[i]) {
        return false;
      }
    }
    return true;
  }
  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl], {
        queryParams: this.routeQueryParams,
        queryParamsHandling: 'merge',
      });
    });
  }

  cancel(): void {
    if (this.formGroup.dirty && this.canEdit()) {
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: 'Close without saving?',
          message: 'This promo has unsaved changes',
          confirmText: 'Continue Editing',
          cancelText: 'Close',
        },
        disableClose: true,
      });
      confirmDialog.afterClosed().subscribe((confirmResult) => {
        if (!confirmResult) {
          this.formGroup.reset();
          this.router.navigate([`../../../`], { relativeTo: this.route, queryParamsHandling: 'preserve' });
        }
      });
    } else {
      this.formGroup.reset();
      this.router.navigate([`../../../`], { relativeTo: this.route, queryParamsHandling: 'preserve' });
    }
  }

  deleteRecord(versionName?: string) {
    if (versionName == 'Base' || this.canEdit() == false) return;
    const record = this.formGroup.getRawValue();
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Delete',
        message: `Are you sure you wish to delete ${record.Detail.Name}?`,
      },
      disableClose: true,
    });

    confirmDialog.afterClosed().subscribe((confirmResult) => {
      if (confirmResult) {
        this.offerpromoService
          .deletePromo(record.Detail.Id, this.eventId)
          .subscribe({
            next: () => {
              this.matSnackBar.open(
                `${record.Detail.Name} deleted`,
                'OK',
                DEFAULT_SNACKBAR_CONFIG
              );
              this.offerpromoService.reload();
              this.router.navigate([`../../../`], { relativeTo: this.route, queryParamsHandling: 'preserve' });
            },
            error: (error) => {
              log.error('Error deleting promo', error);

              if (error.error.value) {
                throw new Error(error.error.value);
              } else {
                throw new Error(error.message);
              }
            },
          });
      }
    });
  }

  deleteBaseRecord() {
    if (this.canEdit() == false) {
      return;
    }
    const record = this.formGroup.getRawValue();
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Delete',
        message: `Are you sure you wish to delete ${record.Detail.Name}?`,
      },
      disableClose: true,
    });

    confirmDialog.afterClosed().subscribe((confirmResult) => {
      if (confirmResult) {
        this.offerpromoService
          .deleteBasePromo(record.Detail.Id, this.eventId)
          .subscribe({
            next: () => {
              this.matSnackBar.open(
                `${record.Detail.Name} deleted`,
                'OK',
                DEFAULT_SNACKBAR_CONFIG
              );
              this.offerpromoService.reload();
              this.router.navigate([`../../../`], { relativeTo: this.route, queryParamsHandling: 'preserve' });
            },
            error: (error) => {
              log.error('Error deleting promo', error);

              if (error.error.value) {
                throw new Error(error.error.value);
              } else {
                throw new Error(error.message);
              }
            },
          });
      }
    });
  }

  updateTableStatus(status: Status) {
    let selectedRecordsDetails: any;
    //selectedRecordsDetails = [JSON.parse(JSON.stringify(this.promoResponse))];
    const responseOffers =
      this.offerService.selection.selected &&
        this.offerService.selection.selected.length > 0
        ? this.offerService.selection.selected
        : [this.promoResponse];
    selectedRecordsDetails = responseOffers;
    this.updateRecordsStatus(selectedRecordsDetails, status);
  }

  getUpdatedObject(selectedRecords: any) {
    let datatoReturn = [];
    if (selectedRecords && selectedRecords.length > 0) {
      for (let index = 0; index <= selectedRecords.length - 1; index++) {
        if (!selectedRecords[index].Detail) {
          datatoReturn.push({
            Detail: selectedRecords[index],
            EventType: 'PROMO',
          });
        } else {
          datatoReturn.push(selectedRecords[index]);
        }
      }
    }
    return datatoReturn;
  }

  updateRecordsStatus(selectedRecordsDetails: Array<any>, status: any) {
    if (selectedRecordsDetails && selectedRecordsDetails.length > 0) {
      const recordstoupdate = selectedRecordsDetails.map((x: any) => {
        return {
          Id: x.Id,
          ClientKey: x.ClientKey,
          UserId: x.UserId,
          Name: x.Name,
          Headline: x.Headline,
          AlternateText: x.AlternateText,
          BodyCopy: x.BodyCopy,
          Disclaimer: x.Disclaimer,
          ClientComment: x.ClientComment,
          StartDate: dayjs(x.StartDate).format('MM/DD/YYYY'),
          EndDate: dayjs(x.EndDate).format('MM/DD/YYYY'),
          Rank: x.Rank,
          TargetURL: x.TargetURL,
          Status: status,
          Version: x.Version,
          Label: 'PROMO',
        };
      });

      this.offerpromoService.updateStatus(recordstoupdate).subscribe({
        next: () => {
          this.getData();
        },
      });
    }
  }

  getCurrentValues(event: any, control: any) { }

  tagSelected(tagId: string): void {
    if (this.updatedOfferTagIds?.length > 0 && this.updatedOfferTagIds.includes(tagId)) {
      return;
    }
    this.updatedOfferTagIds.push(tagId);
  }

  addTag(offerTag: OfferTag): void {
    this.updatedOfferTagIds.push(offerTag.Id);
    this.matSnackBar.open(`${offerTag.OfferTagName} saved`, 'OK', DEFAULT_SNACKBAR_CONFIG);
  }

  removeTag(tagId: string): void {
    this.updatedOfferTagIds = this.updatedOfferTagIds.filter(id => id !== tagId);
  }

  public get additionalTexts() {
    return this.formGroup.controls['Detail']?.controls[
      'AdditionalText'
    ] as FormArray;
  }

  addAdditionalText(datatoAdd: string) {
    const formtoAdd = this.fb.group({
      addtionalTextDetail: [
        datatoAdd,
        [Validators.minLength(1), Validators.maxLength(200)],
      ],
    });
    this.additionalTexts.push(formtoAdd);
  }

  deleteAddtionalText(index: number) {
    if (this.additionalTexts.controls.length > 1) {
      this.additionalTexts.removeAt(index);
    }
  }


  canEdit(): boolean {
    const hasAccess =
      this.authorizationService.checkAccess('edit||offermang') &&
      this.readOnlyMode != true;
    if (hasAccess) {
      this.formGroup.disabled ? this.formGroup.enable() : null;
      this.tagCtrl.enable();
    } else {
      this.formGroup.enabled ? this.formGroup.disable() : null;
      this.tagCtrl.disable();
    }
    return hasAccess;
  }
  openDialog($event: any, offer: any) {
    if (this.isCommentDialogOpened == false) {
      let commentElementStyle = document.getElementById(offer.DomainId) as HTMLElement;
      const dialogConfig = new MatDialogConfig();
      dialogConfig.hasBackdrop = true;
      dialogConfig.disableClose = true;
      //dialogConfig.position = { left: $event.clientX + 'px', top: $event.clientY + 'px' };
      dialogConfig.data = {
        headline: offer.Detail.Headline,
        channel: offer.EventType,
        parentId: offer.Detail.Id,
        clientKey: offer.Detail.ClientKey,
        bodyCopy: offer.Detail.BodyCopy,
        // position:{ left: $event.clientX + 'px', top: $event.clientY + 'px' }
      };
      let confirmDialog = this.dialog.open(CommentsComponent, dialogConfig);
      confirmDialog.afterOpened().subscribe(() => {
        if (commentElementStyle) {
          commentElementStyle.style.color = "blue";
          this.offerpromoService.commentDialogBoxStatusBehaviorSubject.next(true);
        }
      });
      confirmDialog.afterClosed().subscribe((data) => {
        if (data > 0) {
          Object(offer).CommentsCount = data;
        }
        if (commentElementStyle) {
          commentElementStyle.style.color = "black";
          this.offerpromoService.commentDialogBoxStatusBehaviorSubject.next(false);
        }
      });
    }
  }
  ngOnDestroy() { }
}
