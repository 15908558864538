import { Injectable } from '@angular/core';
import { OfferTag, OfferTagsCount } from '..';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, combineLatest, debounceTime, distinctUntilChanged, map, Observable, of, shareReplay, switchMap, tap, } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Filter, OrganizationService } from 'src/app/@shared';
import { v4 as uuidv4 } from 'uuid';
import { DEFAULT_PAGING } from 'src/app/@shared/constants/site.constants';
import * as dayjs from 'dayjs';
const DEFAULT_DATE_RANGE = {
  startDate: '',
  endDate: '',
};
@Injectable({
  providedIn: 'root',
})
export class OfferTagService<T extends OfferTag> {
  // initialize behavior subjects
  private pageBehaviorSubject = new BehaviorSubject(DEFAULT_PAGING);
  private loadingBehaviorSubject = new BehaviorSubject<boolean>(false);
  private searchBehaviorSubject = new BehaviorSubject<string>('');
  private sortBehaviorSubject = new BehaviorSubject({
    active: 'OfferTagName',
    direction: 'asc',
  });
  private reloadBehaviorSubject = new BehaviorSubject<string>('');
  private dateRangeBehaviorSubject = new BehaviorSubject(DEFAULT_DATE_RANGE);

  private viewModeBehaviorSubject = new BehaviorSubject<string>('TABLE');
  private filterBehaviorSubject = new BehaviorSubject<Filter[]>([]);
  private filteredOfferTagsSearch = new BehaviorSubject<string>('');

  // we do not wish to expose our behavior subjects.  create public observables
  public dateRange$ = this.dateRangeBehaviorSubject.asObservable();
  public page$ = this.pageBehaviorSubject.asObservable();
  public search$ = this.searchBehaviorSubject.asObservable();
  public sort$ = this.sortBehaviorSubject.asObservable();
  public isLoading$ = this.loadingBehaviorSubject.asObservable();
  public viewMode$ = this.viewModeBehaviorSubject.asObservable();
  public filters$ = this.filterBehaviorSubject.asObservable();
  public filteredOfferTagsSearch$ = this.filteredOfferTagsSearch.asObservable();
  constructor(private httpClient: HttpClient, private organizationService: OrganizationService) { }

  // create the parameters observable that looks for changes in page, startDate, endDate, etc
  public params$ = combineLatest([
    this.dateRangeBehaviorSubject.pipe(debounceTime(50)),
    this.pageBehaviorSubject, // add debounce if we need to wait for user input ex: .pipe(debounceTime(300)),
    this.sortBehaviorSubject,
    this.searchBehaviorSubject.pipe(debounceTime(1000)),
    this.filterBehaviorSubject.pipe(debounceTime(50)),
    this.reloadBehaviorSubject,
  ]).pipe(
    distinctUntilChanged((previous, current) => {
      // if the values coming down this pipe are the same, don't continue the pipe
      return JSON.stringify(previous) === JSON.stringify(current);
    }),
    map(([dateRange, page, sort, search, filters, reload]) => {
      // set the query string odata parameters
      let params: HttpParams = new HttpParams({
        fromObject: {
          //  mode: viewMode,
          $skip: page.pageIndex * page.pageSize,
          $top: page.pageSize,
          $orderby: `${sort.active} ${sort.direction}`,
          $count: true,
        },
      });
      if (dateRange.startDate) {
        params = params.append('startDate', `${dayjs(dateRange.startDate).format('MM/DD/YYYY')}`);
      }
      if (dateRange.endDate) {
        params = params.append('endDate', `${dayjs(dateRange.endDate).format('MM/DD/YYYY')}`);
      }
      // if there is a search, add the search to the parameters
      if (search.length) {
        params = params.append('$search', `"${search}"`);
      }
      return params;
    })
  );

  public filteredOfferTagsParams$ = combineLatest([
    this.filteredOfferTagsSearch.pipe(debounceTime(300)),
  ]).pipe(
    distinctUntilChanged((previous, current) => {
      return JSON.stringify(previous) === JSON.stringify(current);
    }),
    map(([search]) => {
      let params: HttpParams = new HttpParams();
      if (search.length) {
        params = params.append('$search', `"${search}"`);
      }
      return params;
    })
  );

  // build the list of filter parameters
  private buildFilterParams(filters: Filter[], params: HttpParams): HttpParams {
    // get the division id filters
    const offertagsFilters = filters.filter(item => item.fieldName.toLowerCase() === 'offertags');

    // loop through the division id filters and add filter statement to param
    offertagsFilters.forEach((offertag) => {
      params = params.append('filterOfferTags', offertag.value);
    });

    // return the params
    return params;
  }
  // sets the date range of the event listing
  dateRange(start?: string, end?: string) {
    const viewMode = this.viewModeBehaviorSubject.value;
    const range = { startDate: start as string, endDate: end as string };
    this.dateRangeBehaviorSubject.next(range);
    if (viewMode == "EXPANSION") {
      let paging = JSON.parse(JSON.stringify(DEFAULT_PAGING));
      paging.pageSize = 1000;
      this.pageBehaviorSubject.next(paging);
    } else {
      this.pageBehaviorSubject.next(DEFAULT_PAGING);
    }
  }
  // adds filters to the event listing
  addFilters(newFilters: Filter[]) {
    const filters = this.filterBehaviorSubject.value;

    newFilters.forEach(filter => {
      if (filters.findIndex(item => item.fieldName.toLowerCase() === filter.fieldName.toLowerCase() && item.value.toLowerCase() === filter.value.toLowerCase()) === -1) {
        filters.push(filter)
      }
    });

    this.filterBehaviorSubject.next(filters);
  }

  // removes a filter from the event listing
  removeFilter(filter: Filter) {
    const filters = this.filterBehaviorSubject.value.filter(item => item !== filter);
    this.filterBehaviorSubject.next(filters)
  }

  // removes a filter from the event listing
  removeFilterByFieldName(fieldName: string) {
    console.log('removeFilterByFieldName', this.filterBehaviorSubject.value);
    const filters = this.filterBehaviorSubject.value.filter(item => item.fieldName.toLowerCase() !== fieldName.toLowerCase());
    this.filterBehaviorSubject.next(filters)
  }

  // removes all filters for the event listing
  clearFilters() {
    this.dateRangeBehaviorSubject.next(DEFAULT_DATE_RANGE);
    this.filterBehaviorSubject.next([]);
  }
  // create the offertags observable that calls http get when any of our parameters change
  private offerTagsIncludedExpiredResponse$ = this.params$.pipe(
    tap(() => this.loadingBehaviorSubject.next(true)), // set isLoading to true
    switchMap((_params) =>
      this.httpClient.get(
        `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/OfferTags?ignoreExpired=false`,
        { params: _params }
      )
    ),
    tap(() => this.loadingBehaviorSubject.next(false)), // set isLoading to false
    shareReplay(1) // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
  );

  // offerTag listing
  public offerTagsIncludedExpired$: Observable<T[]> = this.offerTagsIncludedExpiredResponse$.pipe(
    map((res: any) => res.value)
  );

  // total number of offerTag records based on filtering
  public tagsTotalRecordsIncludedExpired$: Observable<number> = this.offerTagsIncludedExpiredResponse$.pipe(
    map((res: any) => res['@odata.count'])
  );

  // create the alloffertags observable that calls http get when any of our parameters change
  private offerTagsExcludedExpiredResponse$ = this.params$.pipe(
    tap(() => this.loadingBehaviorSubject.next(true)), // set isLoading to true
    switchMap((_params) =>
      this.httpClient.get(
        `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/OfferTags`,
        { params: _params })
    ),
    tap(() => this.loadingBehaviorSubject.next(false)), // set isLoading to false
    shareReplay(1) // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
  );

  // offerTag listing
  public offerTagsExcludedExpired$: Observable<T[]> = this.offerTagsExcludedExpiredResponse$.pipe(
    map((res: any) => res.value)
  );

  // total number of offerTag records based on filtering
  public tagsTotalRecordsExcludedExpired$: Observable<number> = this.offerTagsExcludedExpiredResponse$.pipe(
    map((res: any) => res['@odata.count'])
  );

  public GetOfferTagsByIds(OfferTagIds: string[]) {
    let url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/OfferTags/GetOfferTagsByIds`;
    return this.httpClient.post(url, OfferTagIds);
  }

  // set the current page
  page(page: any) {
    this.pageBehaviorSubject.next(page);
  }

  // gets an offerTag by id
  getOfferTag(offerTagId: string): Observable<T> {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/OfferTags/${offerTagId}`;
    return this.httpClient.get<T>(url);
  }

  // gets an offerTag by eventId
  getByEventId(eventId: string): Observable<T[]> {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/OfferTags/GetByEventId?eventId=${eventId}`;
    return this.httpClient.get<T[]>(url).pipe(map((res: any) => res.value));
  }
  // gets an offer tags with count
  getByOfferTagsWithCount(
    eventId: string,
    offersTag: string[]
  ): Observable<OfferTagsCount[]> {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/OfferDomains/GetOfferTagCountsByOfferIds?eventId=${eventId}`;
    return this.httpClient.post<OfferTagsCount[]>(url, offersTag);
  }

  // sets the sort property and order
  sort(sort: any) {
    this.sortBehaviorSubject.next(sort);
  }

  // sets the search phrase
  search(search: string) {
    const page = this.pageBehaviorSubject.value;
    page.pageIndex = 0;
    page.previousPageIndex = 0;
    this.searchBehaviorSubject.next(search);
    this.pageBehaviorSubject.next(page);
  }

  filteredOfferTagsOnSearch(search: string) {
    this.filteredOfferTagsSearch.next(search);
  }

  // reloads/refreshes the offerTag listing
  reload() {
    // reload the OfferTag data
    this.reloadBehaviorSubject.next(uuidv4());
  }

  // up-serts OfferTags
  saveOfferTag(OfferTag: T) {
    let url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/OfferTags`;
    if (!OfferTag.Id || OfferTag.Id === '0') {
      // create new record
      return this.httpClient.post(url, OfferTag);
    } else {
      // edit existing record
      url += `${OfferTag.Id}`;
      return this.httpClient.put(url, OfferTag);
    }
  }

  // deletes an OfferTag by id
  deleteOfferTag(id: string) {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/OfferTags/${id}`;
    return this.httpClient.delete(url);
  }

  deleteOfferTags(offertagIds: string[]) {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/OfferTags/BulkDelete`;
    return this.httpClient.post(url, offertagIds);
  }

  prepareOfferTagString(offertags: any) {
    let urlParams = '';
    if (offertags && offertags.length > 0) {
      for (let index = 0; index <= offertags.length - 1; index++) {
        urlParams += `offerTagIds=${offertags[index]}`;
        if (index != offertags.length - 1) {
          urlParams += '&';
        }
      }
    }
    return urlParams;
  }

  downloadOfferTagsJSON() {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/OfferTags/EtlExport`;
    window.open(url, '_blank');
  }

  downloadOfferTagsCSV() {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/OfferTags/Export`;
    window.open(url, '_blank');
  }
}
